import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import ReactGA from 'react-ga4';
import Theme from './assets/theme/theme';
import AppRouter from './navigation/AppRouter';
import { AuthProvider } from './contexts/AuthContext';
import { ToasterProvider } from './contexts/ToasterContext';
import { OverlayLoadingProvider } from './contexts/OverlayLoadingContext';
import {
  getEnv, HOTJAR_ID, HOTJAR_SNIPPET_VERSION, GOOGLE_ANALYTICS_ID,
} from './api/env';

const App = () => {
  useEffect(() => {
    ReactGA.initialize(getEnv(GOOGLE_ANALYTICS_ID));
    hotjar.initialize({
      id: getEnv(HOTJAR_ID),
      sv: getEnv(HOTJAR_SNIPPET_VERSION),
    });
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <AuthProvider>
          <OverlayLoadingProvider>
            <ToasterProvider>
              <AppRouter />
            </ToasterProvider>
          </OverlayLoadingProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
