import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import {
  FormControl, Grid, InputLabel, MenuItem, Select, Typography,
} from '@mui/material';
import { Divider } from '@doit/pcnt-react-ui-library';
import StepHeader from './StepHeader';
import i18n from '../../../common/i18n';
import LoanDocumentSendContinueLink from './LoadDocumentSendContinueLink';
import useBiometric from '../../../hooks/useBiometric';
import StepsLoanRequest from './StepsLoanRequest';
import LoadDocuments from './LoadDocuments';
import LoadDocumentInput from './LoadDocumentInput';
import LoadDocumentBiometricInput from './LoadDocumentBiometricInput';

const LoadDocumentMobile = ({
  loanRequest, requiredDocuments, onLoadDocument, uploadImageLoading,
  selectedOption, setSelectedOption, documentToUpload, setDocumentToUpload, image, setImage, canContinue,
  sendLinkLoading, onSendLinkToCustomer,
}) => {
  const location = useLocation();
  const { loading: biometricLoading, doOnboarding } = useBiometric();

  const isBiometric = (doc) =>
    doc.name === 'RQFoto' && doc.userInputOptions.inputMethod === '4ID' && !location?.state?.selfieOn;

  return (
    <>
      <StepsLoanRequest stepActive={i18n.StepsLoanRequest[3]} />
      <Grid container sx={{ paddingLeft: '24px', paddingRight: '24px', backgroundColor: '#FFF' }}>
        {/* MUESTRA EL SELECTOR DE FORMAS DE CARGAR LA DOCUMENTACION: ENVIAR LINK O CONTINUAR ACA */}
        {!documentToUpload && (
          <Grid item xs={12}>
            <StepHeader loanRequest={loanRequest} />
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.DocumentUploadPage.title}</Typography>
            <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '32px' }}>
              {i18n.DocumentUploadPage.description}
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="opt-select-label">{i18n.DocumentUploadPage.selectOption}</InputLabel>
              <Select
                labelId="opt-select-label"
                id="opt-select"
                value={selectedOption}
                label={i18n.DocumentUploadPage.selectOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                {i18n.DocumentUploadPage.tabOptions.map((option) => (
                  <MenuItem value={option} key={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* MUESTRA LA SECCION PARA CONTINUAR ENVIANDO LINK AL CLIENTE */}
            {selectedOption === i18n.DocumentUploadPage.tabOptions[0] && (
              <LoanDocumentSendContinueLink loanRequest={loanRequest} onSendLink={onSendLinkToCustomer} loading={sendLinkLoading} />
            )}
            {/* MUESTRA LA SECCION CONTINUAR ACA, MUESTRA LAS CAJITAS DE DOCUMENTOS */}
            {selectedOption === i18n.DocumentUploadPage.tabOptions[1] && (
              <LoadDocuments
                requiredDocuments={requiredDocuments}
                loanRequest={loanRequest}
                setDocumentToUpload={setDocumentToUpload}
                canContinue={canContinue}
              />
            )}
          </Grid>
        )}
        {/* MUESTRA EL INPUT PARA SUBIR EL DOCUMENTO */}
        {documentToUpload && !isBiometric(documentToUpload) && (
          <Grid item xs={12}>
            <LoadDocumentInput
              documentToUpload={documentToUpload}
              setDocumentToUpload={setDocumentToUpload}
              image={image}
              setImage={setImage}
              onLoadDocument={onLoadDocument}
              uploadImageLoading={uploadImageLoading}
            />
          </Grid>
        )}
        {/* CARGA LA FOTO POR BIOMETRIA */}
        {documentToUpload && isBiometric(documentToUpload) && (
          <Grid item xs={12}>
            <LoadDocumentBiometricInput loanRequest={loanRequest} doOnboarding={doOnboarding} biometricLoading={biometricLoading} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

LoadDocumentMobile.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  requiredDocuments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onLoadDocument: PropTypes.func.isRequired,
  uploadImageLoading: PropTypes.bool,
  setDocumentToUpload: PropTypes.func.isRequired,
  documentToUpload: PropTypes.shape(),
  selectedOption: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  image: PropTypes.shape(),
  setImage: PropTypes.func.isRequired,
  canContinue: PropTypes.bool,
  sendLinkLoading: PropTypes.bool,
  onSendLinkToCustomer: PropTypes.func.isRequired,
};

LoadDocumentMobile.defaultProps = {
  uploadImageLoading: false,
  documentToUpload: undefined,
  image: undefined,
  canContinue: false,
  sendLinkLoading: false,
};

export default LoadDocumentMobile;
