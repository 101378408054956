import { useState } from 'react';
import { useParams } from 'react-router';
import { clearValueOnlyNumbers } from '../../utils/cardUtils';
import { useToaster } from '../../contexts/ToasterContext';
import i18n from '../../common/i18n';
import { hasAdvancePayment } from '../../utils/loanRequestUtils';
import { validateDebitCard, validateDebitCardDownPayment } from '../../api/workflowApiService';

const useCustomerDataDebitCardInputController = (loanRequest, methods) => {
  const { showToaster } = useToaster();
  const { hash } = useParams();
  const [debitCardMask, setDebitCardMask] = useState('9999 9999 9999 9999');
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [validateDebitCardMessage, setValidateDebitCardMessage] = useState();
  const [validateDebitCardResultSuccess, setValidateDebitCardResultSuccess] = useState();

  const areAllFieldsCompletedAndValid = () => {
    // Validacion numero de tarjeta
    const cardNumberAux = clearValueOnlyNumbers(cardNumber);
    if (!cardNumberAux || cardNumber.length < 16) {
      return false;
    }

    // Validacion nombre
    if (!cardHolderName || cardHolderName.length === 0) {
      return false;
    }

    // Validacion fecha expiracion
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!regex.test(expiryDate)) {
      return false;
    }
    const [month, year] = expiryDate.split('/').map(Number);
    if (month > 12 || year < new Date().getFullYear() % 100) {
      return false;
    }

    // Validacion CVV
    if (!securityCode || securityCode.length === 0) {
      return false;
    }

    return true;
  };

  const processDebitCardResult = (result) => {
    if (result.valid) {
      // Se setea un valor para que el input required del form, no quede vacio en el submit general
      methods.setValue('PtllaTarjetaDebito', 'dummyValueForm');
      setValidateDebitCardResultSuccess(true);
      // Si code == 0: validacion OK, si no, fondos insuficientes
      setValidateDebitCardMessage(result.code === '0' ? i18n.CustomerData.debitCardValidated : i18n.CustomerData.debitCardRegistrated);
    } else {
      setValidateDebitCardResultSuccess(false);
      if (result.code === '1' && result.requirePhoto) {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardValidationCode1);
      } else if (result.code === '3') {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardValidationCode3);
      } else if (result.code === '5') {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardValidationCode5);
      } else {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardValidationCodeUnknown);
      }
    }
  };

  const processDebitCardDownPaymentResult = (result) => {
    if (result.valid) {
      // Se setea un valor para que el input required del form, no quede vacio en el submit general
      methods.setValue('PtllaTarjetaDebito', 'dummyValueForm');
      setValidateDebitCardResultSuccess(true);
      setValidateDebitCardMessage(i18n.CustomerData.debitCardDownPaymentValidated);
    } else {
      setValidateDebitCardResultSuccess(false);
      if (result.code === '1') {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardDownPaymentCode1);
      } else if (result.code === '2' || result.code === '3') {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardDownPaymentCode2or3);
      } else if (result.code === '4') {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardDownPaymentCode4);
      } else if (result.code === '5') {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardDownPaymentCode5);
      } else if (result.code === '6') {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardDownPaymentCode6);
      } else if (result.code === '7') {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardDownPaymentCode7);
      } else if (result.code === '8') {
        setValidateDebitCardMessage(i18n.CustomerData.debitCardDownPaymentCode8);
      }
    }
  };

  const validateCard = async () => {
    try {
      setLoading(true);
      if (areAllFieldsCompletedAndValid()) {
        const debitCardDTO = {
          customerName: cardHolderName,
          cardNumber: clearValueOnlyNumbers(cardNumber),
          expirationMonth: expiryDate.split('/')[0],
          expirationYear: expiryDate.split('/')[1],
          cvvCode: securityCode,
        };

        if (hasAdvancePayment(loanRequest)) {
          const { data: result } = await validateDebitCardDownPayment(hash, debitCardDTO);
          processDebitCardDownPaymentResult(result);
        } else {
          const { data: result } = await validateDebitCard(hash, debitCardDTO);
          processDebitCardResult(result);
        }
      } else {
        showToaster(i18n.CustomerData.debitCardValidationInput, 'error');
      }
    } catch (error) {
      setValidateDebitCardResultSuccess(false);
      setValidateDebitCardMessage(i18n.CustomerData.debitCardValidationError);
    } finally {
      setLoading(false);
    }
  };

  return {
    debitCardMask,
    cardNumber,
    cardHolderName,
    expiryDate,
    securityCode,
    setDebitCardMask,
    setCardNumber,
    setCardHolderName,
    setExpiryDate,
    setSecurityCode,
    validateCard,
    areAllFieldsCompletedAndValid,
    loading,
    validateDebitCardMessage,
    validateDebitCardResultSuccess,
  };
};

export default useCustomerDataDebitCardInputController;
