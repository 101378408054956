import { useLocation, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import UploadDocumentCard from './UploadDocumentCard';
import useNavigation from '../../../hooks/useNavigation';

const LoadDocuments = ({
  requiredDocuments, loanRequest, setDocumentToUpload, canContinue,
}) => {
  const { hash } = useParams();
  const location = useLocation();
  const { goToSignature, goToCustomerData } = useNavigation();

  const isBiometric = (doc) =>
    doc.name === 'RQFoto' && doc.userInputOptions.inputMethod === '4ID' && !location?.state?.selfieOn;

  const isSelfie = (doc) => doc.name === 'RQFoto';

  const isDisabled = (doc) => {
    let disabled = false;
    if (isBiometric(doc) || isSelfie(doc)) {
      const frontLoaded = requiredDocuments.find((d) => d.name === 'RQDNIfrente' && d.userInputOptions.defaultValue);
      const backLoaded = requiredDocuments.find((d) => d.name === 'RQDNIdorso' && d.userInputOptions.defaultValue);
      disabled = !frontLoaded || !backLoaded;
    }
    return disabled;
  };

  return (
    <>
      <Typography align="left" sx={{ marginTop: '32px', fontSize: '14px' }}>{i18n.DocumentUploadPage.uploadDocumentTitle}</Typography>
      {requiredDocuments.map((document) => (
        <UploadDocumentCard
          key={document.name}
          document={document}
          onClick={() => setDocumentToUpload(document)}
          disabled={isDisabled(document)}
        />
      ))}
      <Button
        variant="primary"
        color="grey"
        onClick={() => goToSignature(loanRequest.hashKey)}
        fullWidth
        disabled={!canContinue}
        sx={{ marginTop: '64px' }}
      >
        {i18n.DocumentUploadPage.continueCTA}
      </Button>
      <Button
        fullWidth
        variant="tertiary"
        color="grey"
        onClick={() => goToCustomerData(hash)}
        sx={{ marginTop: '8px' }}
      >
        {i18n.DocumentUploadPage.backCTA}
      </Button>
    </>
  );
};

LoadDocuments.propTypes = {
  requiredDocuments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loanRequest: PropTypes.shape().isRequired,
  setDocumentToUpload: PropTypes.func.isRequired,
  canContinue: PropTypes.bool,
};

LoadDocuments.defaultProps = {
  canContinue: false,
};

export default LoadDocuments;
