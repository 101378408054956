import { useParams } from 'react-router';
import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import {
  Button, CardActions, CardContent, Divider, Signature,
} from '@doit/pcnt-react-ui-library';
import Card from '../../components/commons/Card';
import Loading from '../../components/commons/Loading';
import useSignatureController from '../../hooks/controllers/useSignatureController';
import StepHeader from './components/StepHeader';
import i18n from '../../common/i18n';
import StepsLoanRequest from './components/StepsLoanRequest';
import useNavigation from '../../hooks/useNavigation';

const SignaturePage = () => {
  const { hash } = useParams();
  const {
    globalLoading,
    loanRequest,
    sendAgreement,
    sendAgreementLoading,
    sigPadRef,
    signature,
    signLoading,
    doSignAgreement,
  } = useSignatureController();
  const { goToUploadDocuments } = useNavigation();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const getContent = () => (
    <>
      <Grid container sx={{ marginBottom: '24px' }}>
        <Grid item xs={12} md={6}>
          <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.SignaturePage.title}</Typography>
          <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '24px' }}>{i18n.SignaturePage.description}</Typography>
          <Typography align="left" sx={{ fontSize: '14px', fontWeight: 600 }}>{i18n.SignaturePage.sendAgreementTitle}</Typography>
          <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '16px' }}>
            {i18n.SignaturePage.sendAgreementDescription}
          </Typography>
          <Box sx={{ marginTop: '24px', textAlign: 'left' }}>
            <Button
              variant="tertiary"
              sx={{
                padding: '0px', display: 'flex', justifyContent: 'flex-start', marginBottom: '24px',
              }}
              onClick={sendAgreement}
              loading={sendAgreementLoading}
            >
              {i18n.SignaturePage.sendAgreementCTA}
              <img src="/images/send-icon.svg" alt="icon" style={{ marginLeft: '8px' }} />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className="sign-pad">
          <Signature
            clearLabel={i18n.SignaturePage.repeatSignature}
            sigPadRef={sigPadRef}
            width="auto"
            placeholder={isMobile ? i18n.SignaturePage.signPlaceholder : undefined}
          />
        </Grid>
      </Grid>
      <CardActions>
        <Box>
          {!isMobile && (
            <Button
              variant="tertiary"
              color="grey"
              onClick={() => goToUploadDocuments(hash)}
              fullWidth={isMobile}
            >
              {i18n.InstallmentPlanPage.installmentPlanBack}
            </Button>
          )}
          <Button
            type="submit"
            color="grey"
            disabled={signature === undefined}
            loading={signLoading}
            fullWidth={isMobile}
            onClick={doSignAgreement}
          >
            {i18n.SignaturePage.signCTA}
          </Button>
          {isMobile && (
            <Button
              variant="tertiary"
              color="grey"
              onClick={() => goToUploadDocuments(hash)}
              fullWidth={isMobile}
              sx={{ marginTop: '12px' }}
            >
              {i18n.InstallmentPlanPage.installmentPlanBack}
            </Button>
          )}
        </Box>
      </CardActions>
    </>
  );

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      <StepsLoanRequest stepActive={i18n.StepsLoanRequest[4]} />
      {isMobile ? (
        <Grid container sx={{ backgroundColor: '#FFF', padding: '24px' }}>
          <Grid item xs={12}>
            <StepHeader loanRequest={loanRequest} />
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            {getContent()}
          </Grid>
        </Grid>
      ) : (
        <>
          <Card>
            <CardContent>
              <StepHeader loanRequest={loanRequest} />
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              {getContent()}
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default SignaturePage;
