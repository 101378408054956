import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Close } from '@mui/icons-material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';

const LoanRequestSendOfferModal = ({
  open, onClose, onConfirm, loanRequests, offerSentOk,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <Grid container sx={{ padding: '32px', paddingTop: '24px' }}>
          <Grid item xs={12} sx={{ textAlign: 'right' }}><IconButton sx={{ zIndex: 100 }} onClick={onClose}><Close /></IconButton></Grid>
          {offerSentOk ? (
            <Grid item xs={12}>
              <Typography
                align="left"
                sx={{
                  fontSize: '20px', fontWeight: 700, display: 'flex', alignItems: 'center',
                }}
              >
                <CheckCircleIcon sx={{ color: '#00875A', marginRight: '8px' }} />
                {i18n.LoanRequestSendOfferModal.successTitle}
              </Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>{i18n.LoanRequestSendOfferModal.successDescription}</Typography>
              <Button
                variant="primary"
                color="grey"
                fullWidth
                onClick={onClose}
                sx={{ marginTop: '32px' }}
              >
                {i18n.LoanRequestSendOfferModal.close}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography align="left" sx={{ fontSize: '20px', fontWeight: 700 }}>
                {i18n.LoanRequestSendOfferModal.title}
              </Typography>
              {loanRequests?.length > 1 ? (
                <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>
                  {parse(fillMessageWith(i18n.LoanRequestSendOfferModal.description[1], loanRequests.length))}
                </Typography>
              ) : (
                <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>
                  {parse(fillMessageWith(i18n.LoanRequestSendOfferModal.description[0], loanRequests[0].customerName.toLowerCase()))}
                </Typography>
              )}
              <Box sx={{ display: { xs: '', md: 'flex' }, justifyContent: { xs: '', md: 'space-between' } }}>
                {!isMobile && (
                  <Button
                    variant="secondary"
                    color="grey"
                    sx={{ marginTop: '40px' }}
                    onClick={onClose}
                  >
                    {i18n.LoanRequestSendOfferModal.cancel}
                  </Button>
                )}
                <Button
                  variant="primary"
                  color="grey"
                  fullWidth={isMobile}
                  onClick={onConfirm}
                  sx={{ marginTop: '40px' }}
                >
                  {i18n.LoanRequestSendOfferModal.cta}
                </Button>
                {isMobile && (
                  <Button
                    variant="tertiary"
                    color="grey"
                    sx={{ marginTop: '16px' }}
                    onClick={onClose}
                    fullWidth
                  >
                    {i18n.LoanRequestSendOfferModal.cancel}
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Dialog>
    </>
  );
};

LoanRequestSendOfferModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loanRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  offerSentOk: PropTypes.bool,
};

LoanRequestSendOfferModal.defaultProps = {
  open: false,
  offerSentOk: false,
};

export default LoanRequestSendOfferModal;
