import {
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LoanRequestsMobile from './components/LoanRequestsMobile';
import LoanRequestsDesktop from './components/LoanRequestsDesktop';

const LoanRequestsPage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <>
      {isMobile ? (
        <LoanRequestsMobile />
      ) : (
        <LoanRequestsDesktop />
      )}
    </>
  );
};

export default LoanRequestsPage;
