import parse from 'html-react-parser';
import {
  Alert, Button, CardContent, Divider,
} from '@doit/pcnt-react-ui-library';
import {
  Box, Grid, Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import i18n from '../../common/i18n';
import { PREPAID_CARD } from '../../constants/commerceContants';
import { fillMessageWith } from '../../utils/functionsUtils';
import Card from '../../components/commons/Card';
import Loading from '../../components/commons/Loading';
import useFinalController from '../../hooks/controllers/useFinalController';
import { moneyFormatterWithoutDecimals, dateFormatter, capitalizeEachWord } from '../../utils/formatterUtils';
// import NpsForm from './components/NpsForm';

const FinalPage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const {
    globalLoading,
    loanRequest,
    documents,
    isApproved,
    isProductToPayVehicleType,
    isPledgeDocCompleted,
    showDanger,
    goHome,
    downloadVoucher,
    downloadDocs,
    onlyRequiredAndNotSettedFilterFunction,
    // showSurvey,
    // setShowSurvey,
    // surveyInfo,
  } = useFinalController();

  const getContent = () => (
    <>
      {/* HEADER */}
      <CardContent style={{ backgroundColor: showDanger ? '#F3D34A' : '#E6F9F0' }}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <img
              src={showDanger ? '/images/icono-danger.svg' : '/images/icono-success.svg'}
              alt="icon"
              style={{ width: '80px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '32px', fontWeight: 700 }}>
              {isApproved ? i18n.FinalPage.approved : i18n.FinalPage.preApproved}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 700 }}>
              {fillMessageWith(i18n.FinalPage.creditNumber, loanRequest.sappCreditId)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      {/* LOAN DATA */}
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={10} md={8}>
            <Grid container>
              <Grid item xs="auto" display="flex" alignItems="left" key="Fecha">
                <Typography>
                  {parse(fillMessageWith(i18n.FinalPage.LoanData.date, dateFormatter(loanRequest.date)))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} md={8}>
            <Grid container columnSpacing={4}>
              <Grid item xs="auto" display="flex" alignItems="left" key="Nombre">
                <Typography align="left">
                  {parse(fillMessageWith(i18n.FinalPage.LoanData.name, capitalizeEachWord(loanRequest.customerName)))}
                </Typography>
              </Grid>
              <Grid item xs="auto" display="flex" alignItems="left" key="DNI">
                <Typography align="right">
                  {parse(fillMessageWith(i18n.FinalPage.LoanData.dni, loanRequest.customerId.substring(2, 10)))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} md={8}>
            <Divider sx={{ marginBottom: '16px' }} />
            <Grid container columnSpacing={8}>
              <Grid item xs={12} md={4} key="Monto-Solicitado" display="flex" alignItems="left">
                <Box display={isMobile ? 'contents' : 'block'}>
                  <Typography>
                    {i18n.FinalPage.LoanData.requestedAmount}
                  </Typography>
                  <Typography style={{ fontWeight: 700 }}>
                    {isMobile && ': '}
                    {moneyFormatterWithoutDecimals(loanRequest.requestedAmount)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} key="Cantidad-Cuotas" display="flex" alignItems="center">
                <Box display={isMobile ? 'inline-flex' : 'block'} sx={{ marginTop: { xs: '16px', md: '' } }}>
                  <Typography>
                    {i18n.FinalPage.LoanData.numberOfInstallments}
                  </Typography>
                  <Typography style={{ fontWeight: 700 }}>
                    {isMobile && ': '}
                    {loanRequest.installmentsNumberSelected}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} key="Monto-Cuota" display="flex" alignItems="right">
                <Box display={isMobile ? 'inline-flex' : 'block'} sx={{ marginTop: { xs: '16px', md: '' } }}>
                  <Typography>
                    {i18n.FinalPage.LoanData.installmentAmount}
                  </Typography>
                  <Typography style={{ fontWeight: 700 }}>
                    {isMobile && ': '}
                    {moneyFormatterWithoutDecimals(loanRequest.installmentAmountSelected)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      {/* MESSAGES */}
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={10} md={8}>

            {/* MENSAJE Manual con Éxito */}
            {!showDanger && loanRequest.saleCompletionManual && (
              <Alert
                severity="info"
                style={{ textAlign: 'left' }}
                icon={(
                  <img
                    src="/images/clock.svg"
                    alt="time"
                    style={{ width: '20px' }}
                  />
                )}
              >
                <Typography>
                  {loanRequest.disbursementMode === PREPAID_CARD && (
                    <>
                      {i18n.FinalPage.Messages.Manual.Success.isPrepaid}
                    </>
                  )}
                  {loanRequest.disbursementMode !== PREPAID_CARD && !isProductToPayVehicleType && (
                    <>
                      {i18n.FinalPage.Messages.Manual.Success.isNotProductToPayVehicleType}
                    </>
                  )}
                  {loanRequest.disbursementMode !== PREPAID_CARD && isProductToPayVehicleType && !isPledgeDocCompleted && (
                    <>
                      {i18n.FinalPage.Messages.Manual.Success.isNotPledgeDocCompleted}
                    </>
                  )}
                  {loanRequest.disbursementMode !== PREPAID_CARD && isProductToPayVehicleType && isPledgeDocCompleted && (
                    <>
                      {i18n.FinalPage.Messages.Manual.Success.isPledgeDocCompleted}
                    </>
                  )}
                </Typography>
              </Alert>
            )}

            {/* MENSAJE Manual con Warning */}
            {showDanger && loanRequest.saleCompletionManual && (
              <Alert severity="warning" style={{ textAlign: 'left' }}>
                <Typography>
                  {i18n.FinalPage.Messages.Manual.Warning.title}
                </Typography>
                <Typography>
                  {parse(i18n.FinalPage.Messages.Manual.Warning.subtitle)}
                </Typography>
                <Typography>
                  <ol style={{ paddingLeft: '16px' }}>
                    {!loanRequest.customerDataCompleted && (
                      <li>
                        {i18n.FinalPage.Messages.Manual.Warning.reqCustomerDataCompleted}
                      </li>
                    )}
                    {!loanRequest.documentsCompleted && !loanRequest.isPaperless && (
                      <li>
                        {i18n.FinalPage.Messages.Manual.Warning.reqFileOrFollowingImages}
                      </li>
                    )}
                    {loanRequest.isPaperless && !loanRequest.hasSigned && (
                      <li>
                        {i18n.FinalPage.Messages.Manual.Warning.reqSign}
                        <ul>
                          {documents.map((doc) => (
                            onlyRequiredAndNotSettedFilterFunction(doc) && (
                              <li>{doc.userInputOptions.label}</li>
                            )
                          ))}
                        </ul>
                      </li>
                    )}
                    <li>
                      {i18n.FinalPage.Messages.Manual.Warning.waitCall}
                    </li>
                  </ol>
                </Typography>
              </Alert>
            )}

            {/* MENSAJE RECHAZADO */}
            {loanRequest.saleCompletionRejected && (
              <Alert severity="warning" style={{ textAlign: 'left' }}>
                <Typography>
                  {i18n.FinalPage.Messages.rejected}
                </Typography>
              </Alert>
            )}

            {/* MENSAJE Bienvenida Automatica */}
            {loanRequest.saleCompletionWelcomeAutomatic && (
              <Alert
                severity="info"
                style={{ textAlign: 'left' }}
              >
                <Typography>
                  <strong>
                    {i18n.FinalPage.Messages.AutomaticWelcome.title}
                  </strong>
                </Typography>
                <Typography>
                  {parse(i18n.FinalPage.Messages.AutomaticWelcome.subtitle)}
                </Typography>
                <Typography>
                  <ul style={{ paddingLeft: '20px' }}>
                    <li>
                      {parse(i18n.FinalPage.Messages.AutomaticWelcome.checkWhatsappMessages)}
                    </li>
                    <li>
                      {parse(i18n.FinalPage.Messages.AutomaticWelcome.answerQuestions)}
                    </li>
                  </ul>
                </Typography>
              </Alert>
            )}

            {/* MENSAJE LIQ Automatica */}
            {loanRequest.saleCompletionAutomatic && (
              <Alert severity="info" style={{ textAlign: 'left' }}>
                <Typography>
                  {i18n.FinalPage.Messages.AutomaticLiq.title}
                </Typography>
                <Typography>
                  {loanRequest.disbursementMode === null
                    ? i18n.FinalPage.Messages.AutomaticLiq.deliverProduct
                    : i18n.FinalPage.Messages.AutomaticLiq.disburseAmount}
                </Typography>
              </Alert>
            )}

            {/* MENSAJE exito Importante */}
            {loanRequest.customerDataCompleted && loanRequest.documentsCompleted && !loanRequest.isPaperless && (
              <Alert severity="warning" style={{ textAlign: 'left' }}>
                <Typography>
                  {i18n.FinalPage.Messages.warnIfPaperless}
                </Typography>
              </Alert>
            )}

          </Grid>

        </Grid>
      </CardContent>

      {/* ACTIONS */}
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={4}>
            <Button variant="secondary" fullWidth color="grey" onClick={goHome}>
              {i18n.FinalPage.CTA.goHome}
            </Button>
          </Grid>
          {loanRequest.isPaperless && loanRequest.collectionModeId !== 3 && (
            <Grid item xs={12} md={4}>
              <Button variant="primary" fullWidth color="grey" onClick={downloadVoucher}>
                {i18n.FinalPage.CTA.downloadDocs}
              </Button>
            </Grid>
          )}
          {!loanRequest.isPaperless && (
            <Grid item xs={12} md={4}>
              <Button variant="primary" fullWidth color="grey" onClick={downloadDocs}>
                {i18n.FinalPage.CTA.downloadDocs}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </>
  );

  if (globalLoading) {
    return <Loading />;
  }

  if (loanRequest) {
    return (
      <>
        {isMobile ? (
          <Grid container sx={{ backgroundColor: '#FFF' }}>
            <Grid item xs={12}>
              {getContent()}
            </Grid>
          </Grid>
        ) : (
          <Card style={{ padding: '0px' }}>
            {getContent()}
          </Card>
        )}

        {/* ********** NPS ********** */}
        {/* <Dialog
          open={showSurvey}
          onClose={() => setShowSurvey(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <NpsForm
            surveyInfo={surveyInfo}
            onFinish={() => setShowSurvey(false)}
          />
        </Dialog> */}
      </>
    );
  }

  return <></>;
};

export default FinalPage;
