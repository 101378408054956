import parse from 'html-react-parser';
import { Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import i18n from '../../../common/i18n';

const HomeServiceAttendance = () => {
  const [, copyToClipboard] = useCopyToClipboard();

  const copyLink = (data) => {
    const value = data.replace(/[^0-9]/, '').replace(/ /g, '');
    console.log(value);
    copyToClipboard(value);
  };

  return (
    <>
      {/* Canales de atención */}
      <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600 }}>
        {i18n.HomePage.clientService.title}
      </Typography>
      <Typography align="left" sx={{ fontSize: '14px', fontWeight: 400, paddingBottom: '10px' }}>
        {parse(i18n.HomePage.clientService.subtitle)}
      </Typography>
      <Button
        className="minimalist"
        variant="tertiary"
        color="grey"
        startIcon={<img src="/images/home-whatsapp-icon.svg" alt="whatsapp" />}
        sx={{
          fontSize: '14px', fontWeight: 400, cursor: 'copy',
        }}
        onClick={() => copyLink(i18n.HomePage.clientService.whatsappNumber)}
      >
        {i18n.HomePage.clientService.whatsappNumber}
      </Button>
      <Button
        className="minimalist"
        variant="tertiary"
        color="grey"
        startIcon={<img src="/images/home-phone-icon.svg" alt="phone" />}
        sx={{
          fontSize: '14px', fontWeight: 400, cursor: 'copy',
        }}
        onClick={() => copyLink(i18n.HomePage.clientService.telephoneNumber)}
      >
        {i18n.HomePage.clientService.telephoneNumber}
      </Button>
    </>
  );
};

export default HomeServiceAttendance;
