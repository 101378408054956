import { Box, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useHomeController from '../../hooks/controllers/useHomeController';
import Loading from '../../components/commons/Loading';
import HomeHeaderActions from './components/HomeHeaderActions';
import HomeCalculator from './components/HomeCalculator';
import HomeComercialPolicies from './components/HomeComercialPolicies';
import HomeServiceAttendance from './components/HomeServiceAttendance';

const useStyles = makeStyles(() => ({
  boxMainContainerMobile: {
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: '#FFF',
  },
  boxCommercialBannerMobile: {
    padding: '40px 4px',
    backgroundColor: 'gray',
    border: '1px solid #E0E0E0',
    boxShadow: '0px 5px 15px 0px #0000001F',
    borderRadius: '4px',
  },
  boxCommercialBannerDesktop: {
    padding: '40px 4px',
    backgroundColor: 'gray',
    border: '1px solid #E0E0E0',
    boxShadow: '0px 5px 15px 0px #0000001F',
    borderRadius: '4px',
    margin: '20px',
    marginLeft: '68px',
    marginRight: '68px',
  },
  boxComercialPoliciesMobile: {
    padding: '16px',
    border: '1px solid #E0E0E0',
    boxShadow: '0px 5px 15px 0px #0000001F',
    borderRadius: '4px',
  },
  boxSectionMobile: {
    padding: '16px 4px',
  },
  boxSectionDesktop: {
    textAlign: 'left',
    padding: '24px',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    boxShadow: '0px 5px 15px 0px #0000001F',
    borderRadius: '4px',
    margin: '20px',
    marginLeft: '68px',
    marginRight: '68px',
  },
}));

const HomePage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const classes = useStyles();

  const {
    loading,
  } = useHomeController();

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Box className={isMobile ? classes.boxMainContainerMobile : {}}>

        {/* Call to actions */}
        <Box className={isMobile ? classes.boxSectionMobile : classes.boxSectionDesktop}>
          <HomeHeaderActions />
        </Box>

        {/*
        Banner comercial. NOT IMPLEMENTED YET
        <Box className={isMobile ? classes.boxCommercialBannerMobile : classes.boxCommercialBannerDesktop}>
          <HomeComercialBanner />
        </Box>
        */}

        {/* Calculadora */}
        <Box className={isMobile ? classes.boxSectionMobile : classes.boxSectionDesktop}>
          <HomeCalculator />
        </Box>

        {/* Políticas comerciales */}
        <Box className={isMobile ? classes.boxComercialPoliciesMobile : classes.boxSectionDesktop}>
          <HomeComercialPolicies />
        </Box>

        {/* Canales de atención */}
        <Box className={isMobile ? classes.boxSectionMobile : classes.boxSectionDesktop}>
          <HomeServiceAttendance />
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
