import { useMediaQuery, useTheme } from '@mui/material';
import useDocumentUploadController from '../../hooks/controllers/useDocumentUploadController';
import Loading from '../../components/commons/Loading';
import LoanDocumentDesktop from './components/LoadDocumentDesktop';
import LoadDocumentMobile from './components/LoadDocumentMobile';

const DocumentUploadPage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const {
    globalLoading,
    loanRequest,
    QR,
    requiredDocuments,
    doUploadDocument,
    uploadImageLoading,
    selectedOption,
    setSelectedOption,
    documentToUpload,
    setDocumentToUpload,
    image,
    setImage,
    canContinue,
    sendLink,
    sendLinkLoading,
    sendLinkToCustomer,
  } = useDocumentUploadController();

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      {isMobile ? (
        <LoadDocumentMobile
          loanRequest={loanRequest}
          requiredDocuments={requiredDocuments}
          onLoadDocument={doUploadDocument}
          uploadImageLoading={uploadImageLoading}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          documentToUpload={documentToUpload}
          setDocumentToUpload={setDocumentToUpload}
          image={image}
          setImage={setImage}
          canContinue={canContinue}
          sendLinkLoading={sendLinkLoading}
          onSendLinkToCustomer={sendLinkToCustomer}
        />
      ) : (
        <LoanDocumentDesktop
          loanRequest={loanRequest}
          QR={QR}
          onSendLink={sendLink}
          sendLinkLoading={sendLinkLoading}
          onSendLinkToCustomer={sendLinkToCustomer}
          requiredDocuments={requiredDocuments}
          setDocumentToUpload={setDocumentToUpload}
          canContinue={canContinue}
          documentToUpload={documentToUpload}
          image={image}
          setImage={setImage}
          onLoadDocument={doUploadDocument}
          uploadImageLoading={uploadImageLoading}
        />
      )}
    </>
  );
};

export default DocumentUploadPage;
