import { Outlet, useNavigate } from 'react-router';
import {
  Box, useMediaQuery, useTheme,
} from '@mui/material';
import SideMenu from './SideMenu';
import SideMenuMobile from './SideMenuMobile';
import { MENU_WIDTH_SIZE } from '../../../utils/menuUtils';
import i18n from '../../../common/i18n';
import useRenewerReport from '../../../hooks/useRenewerReport';
import NotificationModal from './NotificationModal';

const DashboardLayoutComponent = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { generateReport } = useRenewerReport();
  const navigate = useNavigate();

  const handleOptionSelected = (option) => {
    if (option.label === i18n.SideMenu.options.renewers) {
      // Si es renovadores, genero el reporte
      generateReport();
    } else if (option.label === i18n.SideMenu.options.credicuotasClub) {
      window.open('https://www.clubcredicuotas.com.ar/login', '_blank');
    } else if (option.label === i18n.SideMenu.options.news) {
      window.open('https://marketing-files-cc.s3.sa-east-1.amazonaws.com/comercios/Informe_CC.pdf', '_blank');
    } else {
      navigate(option.path);
    }
  };

  return (
    <>
      {!isMobile ? (
        <>
          <SideMenu onSelect={handleOptionSelected} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: `calc(100% - ${MENU_WIDTH_SIZE})`,
              transform: `translate(${MENU_WIDTH_SIZE}, 0px)`,
              paddingBottom: '30px',
            }}
          >
            <Outlet />
          </Box>
        </>
      ) : (
        <>
          <SideMenuMobile onSelect={handleOptionSelected} />
          <Outlet />
        </>
      )}
      <NotificationModal />
    </>
  );
};

export default DashboardLayoutComponent;
