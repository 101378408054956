import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import i18n from '../../common/i18n';
import { useToaster } from '../../contexts/ToasterContext';
import { useAuth } from '../../contexts/AuthContext';
import { calculatorSchemaValidation } from '../../forms/homeSchemaValidations';
import { PLEDGE_SUBPRODUCT_ID } from '../../constants/commerceContants';
import { getVehicleBrands, getVehicleModels, getVehiclePrice } from '../../api/vehicleApiService';
import { getCalculatorParameters, getInstallmentsForCalculator } from '../../api/workflowApiService';
import useRenewerReport from '../useRenewerReport';
import { getApiErrorMessage } from '../../services/errorApiService';

const useHomeController = () => {
  const { showToast } = useToaster();
  const {
    getLoggedUserProductList,
    getLoggedUserBranchId,
    getLoggedUserProvince,
  } = useAuth();
  const { generateReport } = useRenewerReport();

  const [loading, setLoading] = useState(true);

  const [calculatorPercentage, setCalculatorPercentage] = useState();
  const [loadingCalculate, setLoadingCalculate] = useState(false);
  const [productList, setProductList] = useState();
  const [subproductList, setSubproductList] = useState([]);
  const [termList, setTermList] = useState();

  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [vehicleModelLoading, setVehicleModelLoading] = useState();
  const [vehiclePrice, setVehiclePrice] = useState();

  const [installmentList, setInstallmentList] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();

  const methods = useForm({
    resolver: yupResolver(calculatorSchemaValidation),
  });

  const onSelectProduct = (productId, calcMethods) => {
    const subproductListAux = productList.find((productAux) => productId === productAux.idProducto)?.subproductos || [];
    setSubproductList(subproductListAux);
    calcMethods.resetField('subproduct', { defaultValue: 'none' });
    setTermList(undefined);
  };

  const onSelectSubproduct = async (subproductId, calcMethods) => {
    const termsListAux = subproductList.find((subproductAux) => subproductId === subproductAux.idSubProducto)?.plazoParaPago || [];
    if (termsListAux.length === 1) {
      calcMethods.resetField('terms', { defaultValue: termsListAux[0] });
    }
    setTermList(termsListAux);
    if (subproductId === PLEDGE_SUBPRODUCT_ID) {
      try {
        const { data: brandListAux } = await getVehicleBrands();
        setBrandList(brandListAux);
      } catch (error) {
        showToast(i18n.InstallmentPlanPage.vehicleSelection.brandListError, 'error');
      }
    }
  };

  const handleCalculate = async (data) => {
    try {
      setLoadingCalculate(true);
      if (Number(data.subproduct) === PLEDGE_SUBPRODUCT_ID) {
        if (!brand || !model) {
          showToast(i18n.HomePage.calculator.brandAndModelRequired, 'info');
          return;
        }
        if (data.amount > vehiclePrice) {
          showToast(i18n.HomePage.calculator.requiredAmountGreaterThanVehiclePrice, 'info');
          return;
        }
      }

      const dto = {
        amount: data.amount,
        branchId: getLoggedUserBranchId(),
        subProductId: data.subproduct,
        term: data.terms,
        province: getLoggedUserProvince(),
      };

      const { data: installmentListAux } = await getInstallmentsForCalculator(dto);
      setInstallmentList(installmentListAux);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setLoadingCalculate(false);
    }
  };

  const downloadRenewers = async () => {
    generateReport();
  };

  const getModelsByBrand = async (brandId) => {
    try {
      setVehicleModelLoading(true);
      const { data: modelListAux } = await getVehicleModels(brandId);
      setModelList(modelListAux);
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.modelListError, 'error');
    } finally {
      setVehicleModelLoading(false);
    }
  };

  const onChangeBrand = (e) => {
    const brandId = e.target.value;
    setBrand(brandId);
    setModel('none');
    getModelsByBrand(brandId);
  };

  const getVehiclePriceByModelId = async (modelId) => {
    try {
      const res = await getVehiclePrice(modelId);
      if (res.data.list_price) {
        setVehiclePrice(res.data.list_price * calculatorPercentage);
      }
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceError, 'error');
    }
  };

  const onChangeModel = (e) => {
    const modelId = e.target.value;
    setModel(modelId);
    getVehiclePriceByModelId(modelId);
  };

  const init = async () => {
    try {
      setLoading(true);
      setProductList(getLoggedUserProductList());
      const { data: calculatorPercentageAux } = await getCalculatorParameters();
      setCalculatorPercentage(calculatorPercentageAux);
    } catch (error) {
      showToast('ocurrió un error', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    downloadRenewers,
    methods,
    handleCalculate,
    loadingCalculate,
    productList,
    onSelectProduct,
    subproductList,
    onSelectSubproduct,
    termList,
    brandList,
    onChangeBrand,
    brand,
    vehicleModelLoading,
    modelList,
    model,
    onChangeModel,
    vehiclePrice,
    installmentList,
    selectedInstallment,
    setSelectedInstallment,
  };
};

export default useHomeController;
