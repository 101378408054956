import {
  Box,
  Grid,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useState } from 'react';
import i18n from '../../../common/i18n';

const HomeComercialPolicies = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      {/* Políticas comerciales */}
      <Box sx={{ paddingBottom: '16px' }}>
        <Grid container>
          <Grid item xs={11}>
            <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600 }}>
              {i18n.HomePage.commercialPolicies.title}
            </Typography>
            <Typography align="left" sx={{ fontSize: '14px', fontWeight: 400 }}>
              {i18n.HomePage.commercialPolicies.subtitle}
            </Typography>
          </Grid>
          <Grid item xs={1} align="right">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      {!collapsed && (
        <Box sx={{
          padding: '0px 32px',
          borderLeft: '2px solid #E0E0E0',
        }}
        >
          <List sx={{ listStyleType: 'disc', padding: '0px' }}>
            {i18n.HomePage.commercialPolicies.items.map((item) => (
              <ListItem sx={{ display: 'list-item', padding: '1px' }} key={item}>
                <ListItemText>
                  {item}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};

export default HomeComercialPolicies;
