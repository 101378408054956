import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import { publicRoutes } from '../constants/routesConstants';

const LoggedRoute = ({ expired, children }) => {
  const { loggedUser } = useAuth();
  return loggedUser ? children : <Navigate to={expired ? `${publicRoutes.login}?expired` : publicRoutes.login} replace />;
};

LoggedRoute.propTypes = {
  expired: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

LoggedRoute.defaultProps = {
  expired: false,
};

export default LoggedRoute;
