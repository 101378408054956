import download from 'downloadjs';
import { generateRenewerReport } from '../api/renewerApiService';
import i18n from '../common/i18n';
import { useOverlayLoading } from '../contexts/OverlayLoadingContext';
import { useToaster } from '../contexts/ToasterContext';

const useRenewerReport = () => {
  const { setOverlayLoading } = useOverlayLoading();
  const { showToast } = useToaster();

  const generateReport = async () => {
    try {
      setOverlayLoading(true, i18n.HomePage.generatingRenewerReport);
      const res = await generateRenewerReport();
      const contentType = res.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      // Extrae el nombre del archivo (si está en Content-Disposition)
      const contentDisposition = res.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'default.xlsx';

      // Usa downloadjs para descargar el archivo
      download(res.data, filename, contentType);
    } catch (error) {
      showToast(i18n.HomePage.generatingRenewerReportError, 'error');
    } finally {
      setOverlayLoading(false);
    }
  };

  return {
    generateReport,
  };
};

export default useRenewerReport;
