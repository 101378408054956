import { useState } from 'react';
import {
  Dialog,
  FormControl, Grid, InputLabel, MenuItem, Select, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Button, CardActions, CardContent, Divider,
} from '@doit/pcnt-react-ui-library';
import Card from '../../../components/commons/Card';
import i18n from '../../../common/i18n';
import LoanRequestsFilterByDNI from './LoanRequestsFilterByDNI';
import LoanRequestsFilterByLoan from './LoanRequestsFilterByLoan';
import LoanRequestsList from './LoanRequestsList';
import LoanRequestsFilters from './LoanRequestsFilters';
import DistanceSelling from './DistanceSelling';
import LoanRequestSendOfferModal from './LoanRequestSendOfferModal';
import useNavigation from '../../../hooks/useNavigation';
import useLoanRequestsController from '../../../hooks/controllers/useLoanRequestsController';
import Loading from '../../../components/commons/Loading';

const LoanRequestsDesktop = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const {
    globalLoading,
    filterLoading,
    filterFormMap,
    branchList,
    sellerList,
    selectedFilters,
    loanRequestList,
    doSelectAll,
    doSelect,
    sendOfferModalShow,
    clearFilters,
    sendWhatsapp,
    downloadDocs,
    generatePaymentVoucher,
    getAccountStatus,
    sendPaymentVoucher,
    sendOfferLoanModalShow,
    openModalOffer,
    setOpenModalOffer,
    loansOffer,
    sendOffer,
    offerSentOk,
    setOfferSentOk,
    downloadReport,
    downloadReportLoading,
  } = useLoanRequestsController();

  const [selectedFilter, setSelectedFilter] = useState('none');
  const [loanRequest, setLoanRequest] = useState();
  const [showDistanceSelling, setShowDistanceSelling] = useState(false);

  const { resumeLoanRequest } = useNavigation();

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <Card>
      <CardContent>
        <Typography align="left" sx={{ fontSize: '20px', fontWeight: 700 }}>{i18n.LoanRequestsPage.title}</Typography>
        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
        <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600 }}>{i18n.LoanRequestsPage.filter}</Typography>
        <Grid container sx={{ marginTop: '24px' }}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="filter-select-label">{i18n.LoanRequestsPage.filterLabel}</InputLabel>
              <Select
                value={selectedFilter}
                labelId="filter-select-label"
                id="filter-select"
                label={i18n.LoanRequestsPage.filterLabel}
                onChange={(e) => { setSelectedFilter(e.target.value); }}
              >
                {i18n.LoanRequestsPage.filterOptions.map((opt) => (
                  <MenuItem value={opt.id} key={opt.id}>{opt.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider sx={{ marginTop: '24px', display: { xs: 'block', md: 'none' } }} />
          </Grid>
        </Grid>
        {selectedFilter === filterFormMap[0].id && (
          <LoanRequestsFilterByDNI methods={filterFormMap[0].form} onSubmit={filterFormMap[0].submit} />
        )}
        {selectedFilter === filterFormMap[1].id && (
          <LoanRequestsFilterByLoan methods={filterFormMap[1].form} onSubmit={filterFormMap[1].submit} />
        )}
        {selectedFilter === filterFormMap[2].id && (
          <LoanRequestsFilters methods={filterFormMap[2].form} onSubmit={filterFormMap[2].submit} branchList={branchList} sellerList={sellerList} />
        )}
        <CardActions
          sx={{
            marginTop: { xs: '32px', md: '48px' },
            marginBottom: { xs: '32px', md: '64px' },
          }}
        >
          <Button
            color="grey"
            onClick={filterFormMap[selectedFilter]?.form.handleSubmit(filterFormMap[selectedFilter]?.submit)}
            disabled={selectedFilter === 'none'}
            fullWidth={isMobile}
            loading={filterLoading}
          >
            {i18n.LoanRequestsPage.filterCTA}
          </Button>
        </CardActions>
        <LoanRequestsList
          loanRequestList={loanRequestList}
          filters={selectedFilters}
          filterLoading={filterLoading}
          onSelectAll={doSelectAll}
          onSelect={doSelect}
          onSendOffer={sendOfferModalShow}
          onClearFilters={clearFilters}
          onContinue={(lr) => resumeLoanRequest(lr)}
          onSendWhatsapp={sendWhatsapp}
          onDownloadDocs={downloadDocs}
          onPaymentVoucher={generatePaymentVoucher}
          onAccountStatus={getAccountStatus}
          onSendPaymentVoucher={sendPaymentVoucher}
          onSendOfferLoan={sendOfferLoanModalShow}
          onDistanceSelling={(lr) => { setLoanRequest(lr); setShowDistanceSelling(true); }}
          onDownloandReport={downloadReport}
          downloadReportLoading={downloadReportLoading}
        />
      </CardContent>
      {/* MODAL VENTA A DISTANCIA */}
      {showDistanceSelling && !isMobile && (
        <Dialog open>
          <DistanceSelling loanRequest={loanRequest} onClose={() => setShowDistanceSelling(false)} />
        </Dialog>
      )}
      {openModalOffer && (
        <LoanRequestSendOfferModal
          loanRequests={loansOffer}
          open={openModalOffer}
          onClose={() => { setOpenModalOffer(false); setOfferSentOk(false); }}
          onConfirm={sendOffer}
          offerSentOk={offerSentOk}
        />
      )}
    </Card>
  );
};

export default LoanRequestsDesktop;
