import {
  Box,
  Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import i18n from '../../../common/i18n';
import useNavigation from '../../../hooks/useNavigation';
import useHomeController from '../../../hooks/controllers/useHomeController';
import { useAuth } from '../../../contexts/AuthContext';

const useStyles = makeStyles(() => ({
  button: {
    border: '1px solid #E0E0E0',
    boxShadow: '1px  #0000001F',
    borderRadius: '4px',
  },
  itemIcon: {
    padding: '10px',
  },
  itemText: {
    padding: '10px 0px',
  },
}));

const HomeHeaderActions = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { hasRenewers } = useAuth();
  const { goToQuotation, goToLoanRequests } = useNavigation();
  const { downloadRenewers } = useHomeController();

  return (
    <>
      {/* Canales de atención */}
      <Grid container>

        <Grid
          item
          xs={12}
          md={hasRenewers() ? 4 : 6}
          sx={isMobile ? { paddingTop: '0px', paddingBottom: '5px' } : { paddingLeft: '0px', paddingRight: '5px' }}
        >
          <Box
            className={classes.button}
            onClick={goToQuotation}
            sx={{
              cursor: 'pointer',
              backgroundColor: '#DA0067',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px',
            }}
          >
            <Typography
              align="left"
              sx={{
                fontWeight: '700',
                color: '#FFF',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src="/images/home-new-request-icon.svg" alt="nueva solicitud" style={{ marginRight: '8px' }} />
              {i18n.HomePage.callToActions.newLoanRequest}
            </Typography>
            <img src="/images/home-new-request-button.svg" alt="nueva solicitud" />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={hasRenewers() ? 4 : 6}
          sx={isMobile ? { paddingTop: '5px', paddingBottom: '5px' } : { paddingLeft: '5px', paddingRight: '5px' }}
        >
          <Box
            className={classes.button}
            onClick={goToLoanRequests}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px',
            }}
          >
            <Typography
              align="left"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <img src="/images/home-requests-icon.svg" alt="solicitudes" style={{ marginRight: '8px' }} />
              {i18n.HomePage.callToActions.loanRequests}
            </Typography>
            <img src="/images/home-arrow-icon.svg" alt="solicitudes" />
          </Box>
        </Grid>
        {hasRenewers() && (
          <Grid
            item
            xs={12}
            md={4}
            sx={isMobile ? { paddingTop: '5px', paddingBottom: '0px' } : { paddingLeft: '5px', paddingRight: '0px' }}
          >
            <Box
              className={classes.button}
              onClick={downloadRenewers}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '12px',
              }}
            >
              <Typography
                align="left"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <img src="/images/home-star-icon.svg" alt="renovadores" style={{ marginRight: '8px' }} />
                {i18n.HomePage.callToActions.renewers}
              </Typography>
              <img src="/images/home-arrow-icon.svg" alt="renovadores" />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default HomeHeaderActions;
