import PropTypes from 'prop-types';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { defaultComboBoxOption } from '../../../forms/customerDataSchemaValidations';

const CustomerDataTypeAhead = ({ input, methods }) => {
  const filter = (options) => {
    const optionList = options.map((option) => {
      const opt = {
        label: option.descripcion,
        id: option.id,
      };
      return opt;
    });
    optionList.unshift(defaultComboBoxOption);
    return optionList;
  };

  const getSavedValue = (options) => {
    let savedOption = options.find((option) => option.id === input.userInputOptions.defaultValue);
    if (savedOption) {
      savedOption = {
        id: savedOption.id,
        label: savedOption.descripcion,
      };
    }
    return savedOption;
  };

  return (
    <Grid item xs={12} md="auto">
      <Controller
        name={input.name}
        control={methods.control}
        defaultValue={getSavedValue(input.userInputOptions.comboData) || defaultComboBoxOption}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            options={filter(input.userInputOptions.comboData)}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => field.onChange(value)}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            sx={{ minWidth: '216px' }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label={input.userInputOptions.required ? `${input.userInputOptions.label} *` : input.userInputOptions.label}
                  error={!!methods.formState.errors[input.name]}
                  helperText={methods.formState.errors[input.name]?.message}
                />
              </>
            )}
          />
        )}
      />
    </Grid>
  );
};

CustomerDataTypeAhead.propTypes = {
  input: PropTypes.shape().isRequired,
  methods: PropTypes.shape().isRequired,
};

export default CustomerDataTypeAhead;
