import { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import PropTypes from 'prop-types';
import {
  Box, FormControl, Grid, Tab, Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import QRCode from 'react-qr-code';
import {
  Button, CardActions, CardContent, Radio, RadioGroup,
} from '@doit/pcnt-react-ui-library';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from '../../../components/commons/Card';
import StepHeader from './StepHeader';
import i18n from '../../../common/i18n';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import LoanDocumentSendContinueLink from './LoadDocumentSendContinueLink';
import { sendLinkSchemaValidation } from '../../../forms/documentUploadSchemaValidations';
import ComboBoxControlled from '../../../components/commons/ComboBoxControlled';
import StepsLoanRequest from './StepsLoanRequest';
import useNavigation from '../../../hooks/useNavigation';
import LoadDocuments from './LoadDocuments';
import LoadDocumentInput from './LoadDocumentInput';
import LoadDocumentBiometricInput from './LoadDocumentBiometricInput';
import useBiometric from '../../../hooks/useBiometric';
import { isPRDEnvironment } from '../../../utils/environmentUtils';

const LoanDocumentDesktop = ({
  loanRequest, QR, onSendLink, sendLinkLoading, onSendLinkToCustomer, requiredDocuments,
  setDocumentToUpload, canContinue, documentToUpload, image, setImage, onLoadDocument, uploadImageLoading,
}) => {
  const location = useLocation();
  const { hash } = useParams();

  const { loading: biometricLoading, doOnboarding } = useBiometric();

  const [currentTab, setCurrentTab] = useState('2');
  const [validateCustomerOption, setValidateCustomerOption] = useState(isPRDEnvironment() ? 'qr' : 'hereUAT');

  const methods = useForm({
    resolver: yupResolver(sendLinkSchemaValidation),
  });

  const { goToCustomerData } = useNavigation();

  const isBiometric = (doc) =>
    doc.name === 'RQFoto' && doc.userInputOptions.inputMethod === '4ID' && !location?.state?.selfieOn;

  return (
    <>
      <StepsLoanRequest stepActive={i18n.StepsLoanRequest[3]} />
      <Card>
        <CardContent>
          <StepHeader loanRequest={loanRequest} />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.DocumentUploadPage.title}</Typography>
          <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>{i18n.DocumentUploadPage.description}</Typography>

          {/* TABS */}
          <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '32px' }}>
              <TabList onChange={(e, value) => setCurrentTab(value)} aria-label="lab API tabs example">
                <Tab label={i18n.DocumentUploadPage.tabOptions[0]} value="1" />
                <Tab label={i18n.DocumentUploadPage.tabOptions[1]} value="2" />
              </TabList>
            </Box>
            {/* TAB ENVIAR LINK AL CLIENTE */}
            <TabPanel
              value="1"
              style={{
                display: 'flex', paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px',
              }}
            >
              <LoanDocumentSendContinueLink loanRequest={loanRequest} onSendLink={onSendLinkToCustomer} loading={sendLinkLoading} />
            </TabPanel>
            {/* TAB VALIDAR CLIENTE DESDE AQUI */}
            <TabPanel
              value="2"
              style={{
                display: 'flex', paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px',
              }}
            >
              <FormControl sx={{ width: '100%', marginTop: '32px' }}>
                <RadioGroup
                  aria-labelledby="Regular-group"
                  name="radio-buttons-group"
                  onChange={(e) => setValidateCustomerOption(e.target.value)}
                >
                  <Box textAlign="left" className={validateCustomerOption === 'qr' ? 'radio-selected' : ''}>
                    <Radio size="small" value="qr" checked={validateCustomerOption === 'qr'} label={i18n.DocumentUploadPage.tab2.qrOption} />
                  </Box>
                  {validateCustomerOption === 'qr' && (
                    <Box sx={{ display: 'flex', marginTop: '24px', marginBottom: '32px' }}>
                      <QRCode value={QR} />
                    </Box>
                  )}
                  <Box textAlign="left" className={validateCustomerOption === 'link' ? 'radio-selected' : ''}>
                    <Radio size="small" value="link" checked={validateCustomerOption === 'link'} label={i18n.DocumentUploadPage.tab2.linkOption} />
                  </Box>
                  {validateCustomerOption === 'link' && (
                    <Box sx={{ marginTop: '16px' }}>
                      <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSendLink)} style={{ textAlign: 'left' }}>
                          <Typography align="left" sx={{ fontSize: '14px', marginBottom: '24px' }}>
                            {i18n.DocumentUploadPage.tab2.linkOptionDescription}
                          </Typography>
                          <Grid container columnGap="24px" rowGap="24px">
                            <Grid item xs={12} md="auto">
                              <TextInputControlled
                                name="cellphone"
                                label={i18n.DocumentUploadPage.tab2.cellphoneInput}
                                helperText={i18n.DocumentUploadPage.tab2.cellphoneInputHint}
                                variant="outlined"
                                mandatory
                              />
                            </Grid>
                            <Grid item xs={12} md="auto">
                              <ComboBoxControlled
                                name="mode"
                                label={i18n.DocumentUploadPage.tab2.sendMode}
                                options={i18n.DocumentUploadPage.tab2.modeOptions}
                                mandatory
                              />
                            </Grid>
                          </Grid>
                          <Box sx={{ marginTop: '24px', textAlign: 'right' }}>
                            <Button variant="tertiary" color="grey" onClick={() => goToCustomerData(hash)}>{i18n.DocumentUploadPage.back}</Button>
                            <Button type="submit" variant="secondary" loading={sendLinkLoading}>{i18n.DocumentUploadPage.tab2.ctaSendLink}</Button>
                          </Box>
                        </form>
                      </FormProvider>
                    </Box>
                  )}
                  {!isPRDEnvironment() && (
                    <>
                      <Box textAlign="left" className={validateCustomerOption === 'hereUAT' ? 'radio-selected' : ''}>
                        <Radio
                          size="small"
                          value="hereUAT"
                          checked={validateCustomerOption === 'hereUAT'}
                          label={i18n.DocumentUploadPage.tab2.continueHereUAT}
                        />
                      </Box>
                      {validateCustomerOption === 'hereUAT' && (
                        <Grid container>
                          {!documentToUpload && (
                            <Grid item xs={12}>
                              <LoadDocuments
                                requiredDocuments={requiredDocuments}
                                loanRequest={loanRequest}
                                setDocumentToUpload={setDocumentToUpload}
                                canContinue={canContinue}
                              />
                            </Grid>
                          )}
                          {/* MUESTRA EL INPUT PARA SUBIR EL DOCUMENTO */}
                          {documentToUpload && !isBiometric(documentToUpload) && (
                            <Grid item xs={12}>
                              <LoadDocumentInput
                                documentToUpload={documentToUpload}
                                setDocumentToUpload={setDocumentToUpload}
                                image={image}
                                setImage={setImage}
                                onLoadDocument={onLoadDocument}
                                uploadImageLoading={uploadImageLoading}
                              />
                            </Grid>
                          )}
                          {/* CARGA LA FOTO POR BIOMETRIA */}
                          {documentToUpload && isBiometric(documentToUpload) && (
                            <Grid item xs={12}>
                              <LoadDocumentBiometricInput
                                loanRequest={loanRequest}
                                doOnboarding={doOnboarding}
                                biometricLoading={biometricLoading}
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </TabPanel>
          </TabContext>
          {validateCustomerOption === 'qr' && currentTab === '2' && (
            <CardActions>
              <Button variant="tertiary" color="grey" onClick={() => goToCustomerData(hash)}>{i18n.DocumentUploadPage.back}</Button>
            </CardActions>
          )}
        </CardContent>
      </Card>
    </>
  );
};

LoanDocumentDesktop.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  QR: PropTypes.string.isRequired,
  onSendLink: PropTypes.func.isRequired,
  sendLinkLoading: PropTypes.bool,
  onSendLinkToCustomer: PropTypes.func.isRequired,
  requiredDocuments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setDocumentToUpload: PropTypes.func.isRequired,
  canContinue: PropTypes.bool,
  documentToUpload: PropTypes.shape(),
  image: PropTypes.shape(),
  setImage: PropTypes.func.isRequired,
  onLoadDocument: PropTypes.func.isRequired,
  uploadImageLoading: PropTypes.bool,
};

LoanDocumentDesktop.defaultProps = {
  sendLinkLoading: false,
  canContinue: false,
  documentToUpload: undefined,
  image: undefined,
  uploadImageLoading: false,
};

export default LoanDocumentDesktop;
