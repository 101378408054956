import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, Grid, Tooltip, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { Button, Divider } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import InstallmentCard from './InstallmentCard';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';
import useNavigation from '../../../hooks/useNavigation';
import FirstInstallmentBenefit from './FirstInstallmentBenefit';
import { hasFirstInstallmentBenefit } from '../../../utils/installmentUtils';

const InstallmentPlanList = ({
  installments, selectedInstallment, setSelectedInstallment, requestedAmount, advanceAmount, onSubmit, loading, calculator,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { goToQuotation } = useNavigation();

  return (
    <>
      {/* DETALLE PLAN DE CUOTA */}
      <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
      {!calculator && (
        <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600 }}>{i18n.InstallmentPlanPage.offerDetail}</Typography>
      )}
      <Typography
        align="left"
        sx={{
          fontSize: '20px', fontWeight: 700, marginTop: '16px', marginBottom: calculator ? '8px' : '',
        }}
      >
        {fillMessageWith(i18n.InstallmentPlanPage.installmentPlanDetail, moneyFormatter(requestedAmount))}
      </Typography>
      {advanceAmount && (
        <Box sx={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
          <Typography align="left" sx={{ fontSize: '16px' }}>
            {parse(fillMessageWith(i18n.InstallmentPlanPage.advancePaymentAmount, moneyFormatter(advanceAmount)))}
          </Typography>
          <Tooltip title={i18n.InstallmentPlanPage.advancepaymentHint}>
            <HelpOutline sx={{ marginLeft: '10px', color: '#424242' }} />
          </Tooltip>
        </Box>
      )}
      {!calculator && (
        <Box sx={{ marginTop: '8px' }}>
          {/* <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px', marginBottom: '32px' }}>
            {fillMessageWith(
              i18n.InstallmentPlanPage.firstInstallmentExpirationDate,
              dayjs(installments[0].firstInstallmentDueDate).format('DD/MM/YYYY'),
            )}
          </Typography> */}
          {hasFirstInstallmentBenefit(installments) && (
            <FirstInstallmentBenefit amount={selectedInstallment?.firstInstallmentAmountWithDiscount} />
          )}
          <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600, marginBottom: '24px' }}>
            {i18n.InstallmentPlanPage.selectInstallmentPlan}
          </Typography>
        </Box>
      )}
      {/* LISTADO DE CUOTAS */}
      <Grid container>
        {installments?.map((installment) => (
          <Grid item xs={6} md="auto" key={installment.installments} sx={{ padding: '8px 4px' }}>
            <InstallmentCard
              installment={installment}
              selected={installment.installments === selectedInstallment?.installments}
              onSelect={calculator ? undefined : setSelectedInstallment}
            />
          </Grid>
        ))}
      </Grid>
      {!calculator && (
        <Box sx={{ textAlign: 'right', marginTop: '52px' }}>
          {!isMobile && (
            <Button
              variant="tertiary"
              color="grey"
              onClick={goToQuotation}
              loading={loading}
              fullWidth={isMobile}
              sx={{ marginBottom: { xs: '24px', md: '0px' } }}
            >
              {i18n.InstallmentPlanPage.installmentPlanBack}
            </Button>
          )}
          <Button
            variant="primary"
            color="grey"
            disabled={!selectedInstallment}
            onClick={onSubmit}
            loading={loading}
            fullWidth={isMobile}
            sx={{ marginBottom: { xs: '24px', md: '0px' } }}
          >
            {i18n.InstallmentPlanPage.installmentPlanSelectedCTA}
          </Button>
          {isMobile && (
            <Button
              variant="tertiary"
              color="grey"
              onClick={goToQuotation}
              loading={loading}
              fullWidth={isMobile}
              sx={{ marginBottom: { xs: '24px', md: '0px' } }}
            >
              {i18n.InstallmentPlanPage.installmentPlanBack}
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

InstallmentPlanList.propTypes = {
  installments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedInstallment: PropTypes.shape(),
  setSelectedInstallment: PropTypes.func.isRequired,
  requestedAmount: PropTypes.number.isRequired,
  advanceAmount: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  calculator: PropTypes.bool,
};

InstallmentPlanList.defaultProps = {
  selectedInstallment: undefined,
  advanceAmount: undefined,
  loading: false,
  calculator: false,
};

export default InstallmentPlanList;
