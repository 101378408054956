import { Controller, FormProvider } from 'react-hook-form';
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import InputAmountControlled from '../../../components/commons/InputAmountControlled';
import useHomeController from '../../../hooks/controllers/useHomeController';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';
import InstallmentPlanList from '../../loanRequest/components/InstallmentPlanList';

const HomeCalculator = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const {
    methods, handleCalculate, loadingCalculate, productList, onSelectProduct, subproductList, onSelectSubproduct, termList,
    brandList, brand, onChangeBrand, vehicleModelLoading, modelList, model, onChangeModel, vehiclePrice, installmentList,
    selectedInstallment, setSelectedInstallment,
  } = useHomeController();

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleCalculate)}>

          <Box>
            <Typography align="left" sx={{ fontSize: '20px', fontWeight: 700 }}>
              {i18n.HomePage.calculator.title}
            </Typography>
            <Typography align="left" sx={{ fontSize: '14px', fontWeight: 400 }}>
              {i18n.HomePage.calculator.subtitle}
            </Typography>
          </Box>

          <Divider sx={{ border: '1px solid', color: '#E0E0E0', margin: '16px 0px' }} />

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600 }}>
                  {i18n.HomePage.calculator.productSection.title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth error={!!methods.formState?.errors?.product}>
                  <InputLabel id="product-select-label">{i18n.HomePage.calculator.productSection.product.label}</InputLabel>
                  <Controller
                    name="product"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="product-select-label"
                        id="product-select"
                        value={field.value || 'none'}
                        label={i18n.QuotationPage.productLabel}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          onSelectProduct(e.target.value, methods);
                        }}
                      >
                        <MenuItem value="none">{i18n.HomePage.calculator.productSection.product.helper}</MenuItem>
                        {productList?.map((product) => (
                          <MenuItem value={product.idProducto} key={product.idProducto}>{product.nombreProducto}</MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {methods.formState.errors.product && (
                    <FormHelperText>{methods.formState?.errors?.product?.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth error={!!methods.formState?.errors?.subproduct}>
                  <InputLabel id="subproduct-select-label">{i18n.HomePage.calculator.productSection.subProduct.label}</InputLabel>
                  <Controller
                    name="subproduct"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="subproduct-select-label"
                        id="subproduct-select"
                        value={field.value || 'none'}
                        label={i18n.HomePage.calculator.productSection.subProduct.label}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          onSelectSubproduct(e.target.value, methods);
                        }}
                      >
                        <MenuItem value="none">{i18n.HomePage.calculator.productSection.subProduct.helper}</MenuItem>
                        {subproductList?.map((subproduct) => (
                          <MenuItem value={subproduct.idSubProducto} key={subproduct.idSubProducto}>
                            {subproduct.nombreSubProducto}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {methods.formState.errors.subproduct && (
                    <FormHelperText>{methods.formState?.errors?.subproduct?.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {termList && (
                <Grid item xs={12} md={2} sx={termList?.length > 1 ? { display: 'block' } : { display: 'none' }}>
                  <FormControl fullWidth error={!!methods.formState?.errors?.terms}>
                    <InputLabel id="terms-select-label">{i18n.QuotationPage.termsLabel}</InputLabel>
                    <Controller
                      name="terms"
                      control={methods.control}
                      defaultValue={termList[0]}
                      render={({ field }) => (
                        <Select
                          labelId="terms-select-label"
                          id="terms-select"
                          value={field.value}
                          label={i18n.QuotationPage.termsLabel}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        >
                          {termList.map((terms) => (
                            <MenuItem value={terms} key={terms}>{terms}</MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {methods.formState.errors.terms && (
                      <FormHelperText>{methods.formState?.errors?.terms?.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}
            </Grid>
            {brandList?.length > 0 && (
              <Grid container spacing={2} sx={{ marginTop: '16px' }}>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="brand-select-label">{i18n.InstallmentPlanPage.vehicleSelection.brand}</InputLabel>
                    <Select
                      labelId="brand-select-label"
                      id="brand-select"
                      value={brand || 'none'}
                      label={i18n.InstallmentPlanPage.vehicleSelection.brand}
                      onChange={onChangeBrand}
                    >
                      <MenuItem value="none">{i18n.InstallmentPlanPage.vehicleSelection.brandEmptyOption}</MenuItem>
                      {brandList.map((brandAux) => (
                        <MenuItem value={brandAux.id} key={brandAux.id}>{brandAux.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="model-select-label">{i18n.InstallmentPlanPage.vehicleSelection.model}</InputLabel>
                    <Select
                      labelId="model-select-label"
                      id="model-select"
                      value={model || 'none'}
                      label={i18n.InstallmentPlanPage.vehicleSelection.model}
                      onChange={onChangeModel}
                      startAdornment={
                        vehicleModelLoading && (
                          <InputAdornment position="start">
                            <CircularProgress size={20} />
                          </InputAdornment>
                        )
                      }
                    >
                      <MenuItem value="none">{i18n.InstallmentPlanPage.vehicleSelection.modelEmptyOption}</MenuItem>
                      {modelList?.map((modelAux) => (
                        <MenuItem value={modelAux.codia} key={modelAux.codia}>{modelAux.description}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Box>

          <Divider sx={{ border: '1px solid', color: '#E0E0E0', margin: '16px 0px' }} />

          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600 }}>
                  {i18n.HomePage.calculator.amountSection.title}
                </Typography>
                <Typography align="left" sx={{ fontSize: '14px', fontWeight: 400 }}>
                  {i18n.HomePage.calculator.amountSection.subtitle}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} sx={{ padding: '16px 0px' }}>
                <InputAmountControlled
                  name="amount"
                  label={i18n.HomePage.calculator.amountSection.amountInputLabel}
                  variant="outlined"
                  fullWidth
                  mandatory
                />
                {vehiclePrice && (
                  <Typography align="left" sx={{ marginTop: '4px', fontSize: '12px' }}>
                    {fillMessageWith(
                      i18n.HomePage.calculator.amountSection.vehiclePrice,
                      moneyFormatter(vehiclePrice),
                    )}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} align="right">
                <Button
                  variant="secondary"
                  color="primary"
                  type="submit"
                  loading={loadingCalculate}
                  fullWidth={isMobile}
                >
                  {i18n.HomePage.calculator.amountSection.calculateCTA}
                </Button>
              </Grid>
            </Grid>
          </Box>

        </form>
      </FormProvider>
      {installmentList?.length > 0 && (
        <InstallmentPlanList
          installments={installmentList}
          requestedAmount={methods.getValues('amount')}
          selectedInstallment={selectedInstallment}
          setSelectedInstallment={setSelectedInstallment}
          calculator
        />
      )}
    </>
  );
};

export default HomeCalculator;
