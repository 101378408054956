import { Close } from '@mui/icons-material';
import { Dialog, IconButton, Link } from '@mui/material';
import { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';

const NotificationModal = () => {
  const { getNotification, cleanNotification } = useAuth();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    cleanNotification();
  };

  return (
    <>
      {getNotification() && (
        <Dialog open={open}>
          <IconButton
            sx={{
              zIndex: 100, position: 'absolute', top: '5px', right: '5px',
            }}
            onClick={handleClose}
          >
            <Close sx={{ color: 'white', stroke: 'black', strokeWidth: 1.5 }} />
          </IconButton>
          {getNotification().urlImageRedirect ? (
            <Link href={getNotification().urlImageRedirect} target="blank">
              <img src={getNotification().imageUrl} alt="banner" style={{ display: 'block' }} />
            </Link>
          ) : (
            <img src={getNotification().imageUrl} alt="banner" style={{ display: 'block' }} />
          )}
        </Dialog>
      )}
    </>
  );
};

export default NotificationModal;
