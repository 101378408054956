import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';

const LoadDocumentBiometricInput = ({ loanRequest, doOnboarding, biometricLoading }) => (
  <>
    <img src="/images/selfie-image.svg" alt="selfe" style={{ marginTop: '40px', marginBottom: '48px' }} />
    <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600, marginBottom: '12px' }}>
      {i18n.DocumentUploadPage.biometricTitle}
    </Typography>
    <ul style={{ paddingLeft: '30px' }}>
      {i18n.DocumentUploadPage.biometricTips.map((tip) => (
        <li key={tip}>
          <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>{tip}</Typography>
        </li>
      ))}
    </ul>
    <Button
      variant="contained"
      color="grey"
      fullWidth
      sx={{ marginTop: '32px' }}
      onClick={() => doOnboarding(loanRequest.hashKey)}
      loading={biometricLoading}
    >
      {i18n.DocumentUploadPage.biometricCTA}
    </Button>
  </>
);

LoadDocumentBiometricInput.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  doOnboarding: PropTypes.func.isRequired,
  biometricLoading: PropTypes.bool,
};

LoadDocumentBiometricInput.defaultProps = {
  biometricLoading: false,
};

export default LoadDocumentBiometricInput;
