import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const useWebChat = () => {
  const { hasWebChat } = useAuth();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (hasWebChat()) {
      const js = document.createElement('script');
      js.type = 'text/javascript';
      js.async = true;
      js.src = 'https://go.botmaker.com/rest/webchat/p/P1LI3C7ZJZ/init.js';
      document.body.appendChild(js);

      return () => {
        // Elimina el script del DOM
        if (js && js.parentNode) {
          js.parentNode.removeChild(js);
        }

        // Obtengo el iframe de botmaker
        const iframe = document.querySelector('iframe[title="Botmaker"]');

        if (iframe && iframe.parentNode) {
          // Obtengo el contenedor de botmaker para eliminarlo
          const botmakerContainer = iframe.parentNode;
          if (botmakerContainer && botmakerContainer.parentNode) {
            botmakerContainer.parentNode.removeChild(botmakerContainer);
          }
        }
      };
    }
  }, []);
};

export default useWebChat;
