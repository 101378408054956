import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Button } from '@doit/pcnt-react-ui-library';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import i18n from '../../../common/i18n';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import { amountSchemaValidation } from '../../../forms/distanceSellingSchemaValidations';
import useDistanceSellingController from '../../../hooks/controllers/useDistanceSellingController';
import Loading from '../../../components/commons/Loading';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';

const useStyles = makeStyles(() => ({
  list: {
    display: 'flex',
    paddingLeft: '24px',
    margin: 0,
  },
  listItem: {
    marginRight: '48px',
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const DistanceSelling = ({
  loanRequest, onClose,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const classes = useStyles();

  const {
    initLoading, paymentLinkData, loading, generateLink, link, sendLink, copyLink, editLink, editable,
  } = useDistanceSellingController(loanRequest);

  const methods = useForm({
    resolver: yupResolver(amountSchemaValidation),
  });

  if (initLoading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container sx={{ padding: '32px', paddingTop: '24px' }}>
        {!isMobile && (
          <Grid item xs={12} sx={{ textAlign: 'right' }}><IconButton sx={{ zIndex: 100 }} onClick={onClose}><Close /></IconButton></Grid>
        )}
        {/* LINK YA GENERADO */}
        {link ? (
          <Grid item xs={12}>
            <Typography
              align="left"
              sx={{
                fontSize: '20px', fontWeight: 700, display: 'flex', alignItems: 'center',
              }}
            >
              <CheckCircleIcon sx={{ color: '#00875A', marginRight: '8px' }} />
              {i18n.DistanceSelling.generatedTitle}
            </Typography>
            <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>{i18n.DistanceSelling.generatedDescription}</Typography>
            <Typography
              align="left"
              sx={{
                marginTop: '25px', fontSize: '14px', fontWeight: 600, display: 'flex', alignItems: 'center',
              }}
            >
              {fillMessageWith(i18n.DistanceSelling.loanAmount, moneyFormatter(paymentLinkData?.productAmount))}
              {editable && (
                <Button
                  className="minimalist"
                  variant="tertiary"
                  color="grey"
                  startIcon={<img src="/images/edit-icon.svg" alt="edit" />}
                  onClick={editLink}
                  sx={{ fontSize: '12px', fontWeight: 400, marginLeft: '12px' }}
                >
                  {i18n.DistanceSelling.edit}
                </Button>
              )}
            </Typography>
            <Grid container sx={{ marginTop: '27px' }}>
              <Grid item xs={9}>
                <Typography align="left" sx={{ fontSize: '14px' }}>
                  {parse(fillMessageWith(i18n.DistanceSelling.link, link))}
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Button
                  className="minimalist-vertical"
                  variant="tertiary"
                  color="grey"
                  startIcon={<img src="/images/send-icon-grey.svg" alt="edit" />}
                  onClick={sendLink}
                  sx={{ fontSize: '12px', fontWeight: 400 }}
                >
                  {i18n.DistanceSelling.send}
                </Button>
              </Grid>
              <Grid item xs={1.5}>
                <Button
                  className="minimalist-vertical"
                  variant="tertiary"
                  color="grey"
                  startIcon={<img src="/images/copy-icon.svg" alt="edit" />}
                  onClick={copyLink}
                  sx={{ fontSize: '12px', fontWeight: 400 }}
                >
                  {i18n.DistanceSelling.copy}
                </Button>
              </Grid>
            </Grid>
            <Button
              variant="primary"
              color="grey"
              fullWidth
              sx={{ marginTop: '40px' }}
              onClick={onClose}
            >
              {isMobile ? i18n.DistanceSelling.back : i18n.DistanceSelling.close}
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {/* GENERAR LINK */}
            <Typography align="left" sx={{ fontSize: '20px', fontWeight: 700 }}>{i18n.DistanceSelling.title}</Typography>
            <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>{i18n.DistanceSelling.description}</Typography>
            <Typography
              align="left"
              sx={{
                fontSize: '14px', fontWeight: 600, marginTop: '12px', marginBottom: '8px',
              }}
            >
              {i18n.DistanceSelling.requirementTitle}
            </Typography>
            <ul className={classes.list}>
              {i18n.DistanceSelling.requirements.map((item) => (
                <li key={item} className={classes.listItem}>
                  <Typography key={item} sx={{ fontSize: '14px' }}>{item}</Typography>
                </li>
              ))}
            </ul>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(generateLink)}>
                <TextInputControlled
                  name="amount"
                  defaultValue={paymentLinkData?.productAmount}
                  label={i18n.DistanceSelling.amount}
                  variant="outlined"
                  fullWidth
                  mandatory
                  sx={{ marginTop: '32px' }}
                />
                <TextInputControlled
                  name="description"
                  defaultValue={paymentLinkData?.productDescription}
                  label={i18n.DistanceSelling.productDescription}
                  variant="outlined"
                  fullWidth
                  multiline
                  maxRows={4}
                  sx={{ marginTop: '32px' }}
                />
                <Box sx={{ display: { xs: '', md: 'flex' }, justifyContent: { xs: '', md: 'space-between' } }}>
                  {!isMobile && (
                    <Button
                      variant="secondary"
                      color="grey"
                      sx={{ marginTop: '40px' }}
                      onClick={onClose}
                    >
                      {i18n.DistanceSelling.cancel}
                    </Button>
                  )}
                  <Button
                    variant="primary"
                    color="grey"
                    type="submit"
                    fullWidth={isMobile}
                    loading={loading}
                    sx={{ marginTop: '40px' }}
                  >
                    {i18n.DistanceSelling.cta}
                  </Button>
                  {isMobile && (
                    <Button
                      variant="tertiary"
                      color="grey"
                      fullWidth
                      sx={{ marginTop: '16px' }}
                      onClick={onClose}
                    >
                      {i18n.DistanceSelling.back}
                    </Button>
                  )}
                </Box>
              </form>
            </FormProvider>
          </Grid>
        )}
      </Grid>
    </>
  );
};

DistanceSelling.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DistanceSelling;
