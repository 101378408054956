import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '@doit/pcnt-react-ui-library';
import {
  Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircleIcon from '@mui/icons-material/Circle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Card from '../../../components/commons/Card';
import i18n from '../../../common/i18n';
import { SELLER_STATUS } from '../../../constants/commerceAffiliationRequestConstants';
import EditUserBranchForm from './EditUserBranchForm';

const ListBranch = ({
  sellerList,
  onDelete,
  branchList,
  onSubmitEdit,
}) => {
  const [selectedSeller, setSelectedSeller] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [openForm, setOpenForm] = useState();
  const open = Boolean(anchorEl);

  useEffect(() => {
    setOpenForm(false);
  }, []);

  const handleClick = (event, seller) => {
    setAnchorEl(event.currentTarget);
    setSelectedSeller(seller);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleClose = () => {
    setAnchorEl();
  };

  const handleEdit = () => {
    setOpenForm(true);
    setAnchorEl();
  };

  const handleCancel = () => {
    onDelete(selectedSeller);
    setAnchorEl();
  };

  const getRoleDescription = (role) =>
    i18n.ManageBranchesPage.roles.find((r) => r.id === role).label;

  return (
    <Card sx={{ padding: '32px 24px' }}>
      {openForm && (
        <EditUserBranchForm
          open={openForm}
          onClose={handleCloseForm}
          onSubmit={onSubmitEdit}
          seller={selectedSeller}
          branchList={branchList}
        />
      )}
      <CardContent>
        <Typography align="left" sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '32px' }}>
          {i18n.ManageBranchesPage.sectionTitles[2]}
        </Typography>
        {sellerList?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{i18n.ManageBranchesPage.tableHeaders[0]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[1]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[2]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[3]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[4]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[5]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[6]}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {sellerList.map((seller) => (
                  <TableRow key={seller.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{`${seller.firstname} ${seller.lastname}`}</TableCell>
                    <TableCell>{seller.identificationNumber}</TableCell>
                    <TableCell>{seller.phone}</TableCell>
                    <TableCell>{seller.email}</TableCell>
                    <TableCell>{getRoleDescription(seller.profile)}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{seller.branch}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <CircleIcon
                          style={{ fontSize: '10px', marginRight: '4px' }}
                          sx={seller.status === SELLER_STATUS.FINALIZED.id ? { color: '#02C66A' } : { color: '#F5A623' }}
                        />
                        {SELLER_STATUS[seller.status].description}
                      </Box>
                    </TableCell>
                    {seller.status === SELLER_STATUS.FINALIZED.id ? (
                      <TableCell />
                    ) : (
                      <TableCell>
                        <IconButton
                          id={`basic-menu-${seller.id}`}
                          aria-controls={open ? `basic-menu-${seller.id}` : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(event) => handleClick(event, seller)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id={`basic-menu-${seller.id}`}
                          anchorEl={anchorEl}
                          open={open && selectedSeller?.id === seller.id}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': `basic-menu-${seller.id}`,
                          }}
                        >
                          <MenuItem onClick={() => handleEdit()}>
                            <ListItemIcon><BorderColorIcon /></ListItemIcon>
                            <ListItemText>{i18n.ManageBranchesPage.ctaEdit}</ListItemText>
                          </MenuItem>
                          <MenuItem onClick={() => handleCancel()}>
                            <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
                            <ListItemText>{i18n.ManageBranchesPage.ctaCancel}</ListItemText>
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ backgroundColor: '#f5f5f5', padding: '24px' }}>
            <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{i18n.ManageBranchesPage.emptySellerList[0]}</Typography>
            <Typography sx={{ fontSize: '16px', marginTop: '12px' }}>{i18n.ManageBranchesPage.emptySellerList[1]}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

ListBranch.propTypes = {
  sellerList: PropTypes.arrayOf(PropTypes.shape()),
  branchList: PropTypes.arrayOf(PropTypes.shape()),
  onDelete: PropTypes.func.isRequired,
  onSubmitEdit: PropTypes.func.isRequired,
};

ListBranch.defaultProps = {
  sellerList: undefined,
  branchList: undefined,
};

export default ListBranch;
