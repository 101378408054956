import {
  Navigate, Route, BrowserRouter, Routes,
} from 'react-router-dom';
import { publicRoutes, privateRoutes } from '../constants/routesConstants';
import LoginPage from '../pages/login/loginPage';
import ForgotPasswordPage from '../pages/login/forgotPasswordPage';
import ExpiredPasswordPage from '../pages/login/expiredPasswordPage';
import RestorePasswordPage from '../pages/login/restorePasswordPage';
import PublicRoute from './PublicRoute';
import ProtectedRoutes from './ProtectedRoutes';
import RedirectPage from '../pages/loanRequest/redirectPage';
import Analytics from '../components/commons/Analytics';

const AppRouter = () => (
  <BrowserRouter>
    <Analytics />
    <Routes>
      <Route path={publicRoutes.login} element={<PublicRoute><LoginPage /></PublicRoute>} />
      <Route path={publicRoutes.forgotPassword} element={<PublicRoute><ForgotPasswordPage /></PublicRoute>} />
      <Route path={publicRoutes.expiredPassword} element={<PublicRoute><ExpiredPasswordPage /></PublicRoute>} />
      <Route path={publicRoutes.restorePassword} element={<PublicRoute><RestorePasswordPage /></PublicRoute>} />
      <Route path={privateRoutes.redirect[1]} element={<RedirectPage />} />
      <Route path={`${privateRoutes.dashboard}*`} element={<ProtectedRoutes />} />
      <Route path="*" element={<Navigate to={publicRoutes.login} replace />} />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
