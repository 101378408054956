import { fetch } from './axiosInstance';
import { CREDICUOTAS_COMMERCE_BACKEND_URL, getEnv } from './env';

export const getBranches = () =>
  fetch({
    url: '/loanrequests/branches',
    method: 'get',
  });

export const getSellerList = () =>
  fetch({
    url: '/loanrequests/sellers',
    method: 'get',
  });

export const getLoanRequests = (filters) =>
  fetch({
    url: '/loanrequests',
    method: 'get',
    params: filters,
  });

export const isExpired = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/isexpired`,
    method: 'get',
  });

export const openPdfDocumentInNewTab = (hash) =>
  window.open(`${getEnv(CREDICUOTAS_COMMERCE_BACKEND_URL)}/documents/download/${hash}/documentation`);

export const generatePaymentVoucherApi = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/generatePaymentVoucher`,
    method: 'post',
  });

export const getAccountStatusDocument = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/accountStatusDocument`,
    method: 'get',
    responseType: 'blob',
  });

export const sendSMSWithPaymentVoucherURL = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/sendSmsPaymentVoucherUrl`,
    method: 'post',
  });

export const generatePaymentLink = (paymentLink) =>
  fetch({
    url: '/loanrequests/generatePaymentLink',
    method: 'post',
    data: paymentLink,
  });

export const generateShowcaseSms = (paymentLink) =>
  fetch({
    url: '/loanrequests/generateShowcaseSms',
    method: 'post',
    data: paymentLink,
  });

export const cleanPaymentLink = (paymentLink) =>
  fetch({
    url: '/loanrequests/cleanPaymentLink',
    method: 'post',
    data: paymentLink,
  });

export const sendReportCampaign = (data) =>
  fetch({
    url: '/loanrequests/sendReportCampaign',
    method: 'post',
    data,
  });

export const getReport = (filters) =>
  fetch({
    url: '/loanrequests/report',
    method: 'get',
    params: filters,
    responseType: 'blob',
  });
