import {
  Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  Alert, Button, CardActions, CardContent, Divider,
} from '@doit/pcnt-react-ui-library';
import Card from '../../../components/commons/Card';
import InstallmentPlanHeaderSection from './InstallmentPlanHeaderSection';
import StepHeader from './StepHeader';
import i18n from '../../../common/i18n';
import useNavigation from '../../../hooks/useNavigation';
import StepsLoanRequest from './StepsLoanRequest';

const InstallmentPlanReject = ({ loanRequest, doReload }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { goToQuotation } = useNavigation();

  return (
    <>
      {isMobile ? (
        <>
          <StepsLoanRequest stepActive={i18n.StepsLoanRequest[1]} />
          <Grid container sx={{ paddingLeft: '24px', paddingRight: '24px', backgroundColor: '#FFF' }}>
            <Grid item xs={12}>
              <InstallmentPlanHeaderSection loanRequest={loanRequest} onReload={doReload} />
              <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
              <StepHeader loanRequest={loanRequest} />
              <Alert severity="error" sx={{ marginTop: '32px' }}>
                <Typography align="left" sx={{ fontSize: '14px', fontWeight: 700 }}>{i18n.InstallmentPlanPage.rejected}</Typography>
                <Typography align="left" sx={{ fontSize: '14px' }}>{loanRequest.response.rejectedReason}</Typography>
              </Alert>
              <CardActions sx={{ marginTop: '32px', marginBottom: '32px' }}>
                <Button color="grey" onClick={goToQuotation} fullWidth>
                  {i18n.InstallmentPlanPage.back}
                </Button>
              </CardActions>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <StepsLoanRequest stepActive={i18n.StepsLoanRequest[1]} />
          <Card>
            <CardContent>
              <InstallmentPlanHeaderSection loanRequest={loanRequest} onReload={doReload} />
            </CardContent>
            <Divider sx={{ margin: '8px 16px' }} />
            <CardContent>
              <StepHeader loanRequest={loanRequest} />
              <Alert severity="error" sx={{ marginTop: '32px', maxWidth: '652px' }}>
                <Typography align="left" sx={{ fontSize: '14px', fontWeight: 700 }}>{i18n.InstallmentPlanPage.rejected}</Typography>
                <Typography align="left" sx={{ fontSize: '14px' }}>{loanRequest.response.rejectedReason}</Typography>
              </Alert>
            </CardContent>
            <CardActions sx={{ marginTop: '32px' }}>
              <Button color="grey" onClick={goToQuotation}>
                {i18n.InstallmentPlanPage.back}
              </Button>
            </CardActions>
          </Card>
        </>
      )}
    </>
  );
};

InstallmentPlanReject.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  doReload: PropTypes.func.isRequired,
};

export default InstallmentPlanReject;
