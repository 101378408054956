import parse from 'html-react-parser';
import {
  Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { capitalize, capitalizeEachWord, moneyFormatter } from '../../../utils/formatterUtils';
import {
  getCalculatedAmountToDisburse, getPrePaidAmount, getProductName, getVehicleBrand, getVehicleModel, isPrePaid, isProductToPayVehicle,
} from '../../../utils/loanRequestUtils';
import { useAuth } from '../../../contexts/AuthContext';
import PremiumCustomerModal from './PremiumCustomerModal';

const StepHeader = ({ loanRequest }) => {
  const { getLoggedUserProductList } = useAuth();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const getData = () => (
    <Grid container mt={2} columnGap={2} rowGap={1}>
      <Grid item md="auto">
        <Typography align="left" sx={{ fontSize: '0.875rem' }}>
          {parse(fillMessageWith(i18n.StepHeader.idCredit, loanRequest.sappCreditId))}
        </Typography>
      </Grid>
      <Grid item md="auto">
        <Typography align="left" sx={{ fontSize: '0.875rem' }}>
          {parse(fillMessageWith(i18n.StepHeader.product, capitalize(getProductName(loanRequest, getLoggedUserProductList()))))}
        </Typography>
      </Grid>
      <Grid item md="auto">
        <Typography align="left" sx={{ fontSize: '0.875rem' }}>
          {parse(fillMessageWith(i18n.StepHeader.customerName, capitalizeEachWord(loanRequest.customerName.toLowerCase())))}
        </Typography>
      </Grid>
      <Grid item md="auto">
        <Typography align="left" sx={{ fontSize: '0.875rem' }}>
          {parse(fillMessageWith(i18n.StepHeader.customerTaxId, loanRequest.customerId))}
        </Typography>
      </Grid>
      {loanRequest.requestedAmount && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(i18n.StepHeader.requestedAmount, moneyFormatter(getCalculatedAmountToDisburse(loanRequest))))}
          </Typography>
        </Grid>
      )}
      {loanRequest.requestedAmount && isPrePaid(loanRequest) && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(i18n.StepHeader.cancelAmount, moneyFormatter(getPrePaidAmount(loanRequest))))}
          </Typography>
        </Grid>
      )}
      {loanRequest.requestedAmount && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(
              i18n.StepHeader.paymentPlan,
              loanRequest.installmentsNumberSelected,
              moneyFormatter(loanRequest.installmentAmountSelected),
            ))}
          </Typography>
        </Grid>
      )}
      {isProductToPayVehicle(loanRequest) && getVehicleBrand(loanRequest) && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(
              i18n.StepHeader.vehicle,
              getVehicleBrand(loanRequest),
              getVehicleModel(loanRequest),
            ))}
          </Typography>
        </Grid>
      )}
      {loanRequest.advancePaymentAmount > 0 && (
        <Grid item md="auto">
          <Typography align="left" sx={{ fontSize: '0.875rem' }}>
            {parse(fillMessageWith(i18n.StepHeader.advanceAmount, moneyFormatter(loanRequest.advancePaymentAmount)))}
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {isMobile ? (
        <Accordion
          title={i18n.StepHeader.title}
          variant="outline"
          expandIcon={<ExpandMoreIcon fontSize="small" />}
        >
          {getData()}
        </Accordion>
      ) : (
        <>
          <Typography align="left" sx={{ fontWeight: 600 }}>{i18n.StepHeader.title}</Typography>
          {getData()}
        </>
      )}
      {!loanRequest.response.rejected
      && (loanRequest.response.prePaidLoan || loanRequest.customerInActiveCampaign)
        ? <PremiumCustomerModal /> : <></>}
    </>
  );
};

StepHeader.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default StepHeader;
