import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const NumericFormatCustom = forwardRef(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalScale={0}
        thousandSeparator="."
        decimalSeparator=","
        valueIsNumericString
        prefix="$"
      />
    );
  },
);

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const InputAmountControlled = ({
  name, label, mandatory, helperText, defaultValue, onBlur, onChange, ...props
}) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...props}
          {...field}
          helperText={errors[name]?.message || helperText}
          error={!!errors[name]}
          label={mandatory ? `${label}*` : label}
          refs={field}
          value={field.value || ''}
          inputRef={field.ref}
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          onBlur={onBlur}
          onChange={(e) => {
            field.onChange(e);
            if (onChange) onChange(e);
          }}
        />
      )}
    />
  );
};

InputAmountControlled.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

InputAmountControlled.defaultProps = {
  defaultValue: '',
  mandatory: false,
  variant: 'standard',
  helperText: undefined,
  onBlur: undefined,
  onChange: undefined,
};

export default InputAmountControlled;
