import * as yup from 'yup';
import i18n from '../common/i18n';

// eslint-disable-next-line import/prefer-default-export
export const calculatorSchemaValidation = yup.object().shape({
  product: yup.string()
    .required(i18n.schemaValidation.product.required)
    .notOneOf(['none'], i18n.schemaValidation.product.required),
  subproduct: yup.string()
    .required(i18n.schemaValidation.subproduct.required)
    .notOneOf(['none'], i18n.schemaValidation.subproduct.required),
  terms: yup.string().required(i18n.schemaValidation.terms.required),
  amount: yup.string().required(''),
});
