import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@doit/pcnt-react-ui-library';
import { inputAmountSchemaValidation } from '../../../forms/installmentPlanSchemaValidations';
import i18n from '../../../common/i18n';
import InputAmountControlled from '../../../components/commons/InputAmountControlled';
import useNavigation from '../../../hooks/useNavigation';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';
import { getMaxAmountAvailableConsideringDualOffer } from '../../../utils/loanRequestUtils';

const InstallmentPlanInputAmountForm = ({
  onSubmit, loading, canBack, onChange, loanRequest,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const methods = useForm({
    resolver: yupResolver(inputAmountSchemaValidation),
  });
  const { goToQuotation } = useNavigation();

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container sx={{ marginTop: '32px' }}>
            <Grid item xs={12}>
              <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.InstallmentPlanPage.inputAmount.title}</Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>{i18n.InstallmentPlanPage.inputAmount.description}</Typography>
              <Box sx={{ textAlign: 'left', marginTop: '32px' }}>
                <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '16px' }}>
                  {parse(fillMessageWith(
                    i18n.InstallmentPlanPage.availableAmount,
                    getMaxAmountAvailableConsideringDualOffer(loanRequest) > 0
                      ? moneyFormatter(parseInt(getMaxAmountAvailableConsideringDualOffer(loanRequest), 10)) : '-',
                  ))}
                </Typography>
                <InputAmountControlled
                  name="amount"
                  label={i18n.InstallmentPlanPage.inputAmount.input}
                  helperText={i18n.InstallmentPlanPage.inputAmount.hint}
                  variant="outlined"
                  mandatory
                  fullWidth={isMobile}
                  onChange={onChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'right', marginTop: '32px' }}>
                {canBack && !isMobile && (
                  <Button
                    variant="tertiary"
                    color="grey"
                    onClick={goToQuotation}
                    fullWidth={isMobile}
                    sx={{ marginBottom: { xs: '24px', md: '0px' } }}
                  >
                    {i18n.InstallmentPlanPage.installmentPlanBack}
                  </Button>
                )}
                <Button type="submit" variant="secondary" loading={loading} fullWidth={isMobile}>{i18n.InstallmentPlanPage.inputAmount.cta}</Button>
                {canBack && isMobile && (
                  <Button
                    variant="tertiary"
                    color="grey"
                    onClick={goToQuotation}
                    fullWidth={isMobile}
                    sx={{ marginTop: { xs: '12px', md: '0px' } }}
                  >
                    {i18n.InstallmentPlanPage.installmentPlanBack}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

InstallmentPlanInputAmountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  canBack: PropTypes.bool,
  loanRequest: PropTypes.shape().isRequired,
};

InstallmentPlanInputAmountForm.defaultProps = {
  loading: false,
  canBack: false,
};

export default InstallmentPlanInputAmountForm;
