import { Card, CardActionArea, CardContent } from '@doit/pcnt-react-ui-library';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';
import {
  getDiscountAmount, getDiscountPercentage, hasDiscount, hasPromoCADECOM,
} from '../../../utils/installmentUtils';

const useStyles = makeStyles((theme) => ({
  default: {
    border: '1px solid #E0E0E0',
    paddingTop: '8px',
    boxShadow: 'none',
    height: '100%',
    width: '100%',
    padding: '1px',
  },
  promo: {
    paddingTop: '0px',
    borderTop: `8px solid ${theme.palette.primary.main}`,
  },
  selected: {
    padding: '0px',
    paddingTop: '8px',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.primary.main}0D`,
  },
  selectedPromo: {
    padding: '0px',
    paddingTop: '0px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderTop: `8px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.primary.main}0D`,
  },
  infoContainer: {
    minHeight: '112px',
    minWidth: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  caemon: {
    textAlign: 'left',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    marginBottom: '8px',
  },
  installments: {
    textAlign: 'left',
    fontSize: '14px',
  },
  amount: {
    textAlign: 'left',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 700,
    fontSize: '20px',
  },
  originalAmount: {
    fontSize: '12px',
    color: '#757575',
    marginLeft: '8px',
    textDecoration: 'line-through',
  },
  check: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  discount: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    fontSize: '12px',
  },
}));

const InstallmentCard = ({
  installment, onSelect, selected,
}) => {
  const classes = useStyles();
  return (
    <>
      <Card className={clsx(classes.default, {
        [classes.selected]: selected && !hasDiscount(installment),
        [classes.selectedPromo]: selected && hasDiscount(installment),
        [classes.promo]: hasDiscount(installment),
      })}
      >
        <CardActionArea onClick={onSelect ? () => onSelect(installment) : () => {}} sx={{ height: '100%' }}>
          <CardContent>
            <Box className={classes.infoContainer}>
              {onSelect && (
                <img src={selected ? '/images/check-circle-selected.svg' : '/images/check-circle.svg'} alt="check" className={classes.check} />
              )}
              {hasPromoCADECOM(installment) && (
                <Typography className={classes.caemon}>
                  {i18n.InstallmentCard.cadecom}
                </Typography>
              )}
              <Typography className={classes.installments}>
                {fillMessageWith(i18n.InstallmentCard.installments, installment.installments)}
              </Typography>
              <Box display="flex" alignItems="baseline">
                <Typography className={classes.amount}>
                  {moneyFormatter(installment.amount)}
                </Typography>
                {hasDiscount(installment) && (
                  <Typography className={classes.originalAmount}>
                    {moneyFormatter(installment.amountOriginalLine)}
                  </Typography>
                )}
              </Box>
              {hasDiscount(installment) && (
                <>
                  <Typography className={classes.discount}>
                    {parse(fillMessageWith(i18n.InstallmentCard.discount, getDiscountPercentage(installment)))}
                  </Typography>
                  <Typography className={classes.discount}>
                    <b>{`-${moneyFormatter(getDiscountAmount(installment))}`}</b>
                  </Typography>
                </>
              )}
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

InstallmentCard.propTypes = {
  installment: PropTypes.shape().isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

InstallmentCard.defaultProps = {
  selected: false,
  onSelect: undefined,
};

export default InstallmentCard;
