import { Box, Grid, Typography } from '@mui/material';
import i18n from '../../../common/i18n';

const PremiumCustomerModal = () => (
  <Grid container>
    <Grid
      item
      xs={12}
      md={10}
      sx={{
        backgroundColor: '#FDF2F7',
        border: '1px solid #DA0067',
        borderRadius: '4px',
        padding: '16px',
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '0px',
        marginTop: '24px',
      }}
    >
      <img src="/images/benefit-icon.svg" alt="Beneficio" style={{ marginRight: '12px' }} />
      <Box>
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: { xs: '14px', md: '16px' },
            fontWeight: 700,
          }}
        >
          {i18n.premiumCustomerModal.title}
        </Typography>
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '14px',
          }}
        >
          {i18n.premiumCustomerModal.subtitle}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

export default PremiumCustomerModal;
