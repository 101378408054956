import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Grid, Typography } from '@mui/material';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';

const FirstInstallmentBenefit = ({ amount }) => (
  <Grid container>
    <Grid
      item
      xs={12}
      md={10}
      sx={{
        backgroundColor: '#FDF2F7',
        border: '1px solid #DA0067',
        borderRadius: '4px',
        padding: '16px',
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '32px',
      }}
    >
      <img src="/images/benefit-icon.svg" alt="Beneficio" style={{ marginRight: '12px' }} />
      <Box>
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: { xs: '14px', md: '16px' },
            fontWeight: 700,
          }}
        >
          {i18n.InstallmentPlanPage.firstInstallmentBenefit.title}
        </Typography>
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '14px',
          }}
        >
          {parse(fillMessageWith(
            i18n.InstallmentPlanPage.firstInstallmentBenefit.description,
            amount >= 0 ? moneyFormatter(amount) : '',
          ))}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

FirstInstallmentBenefit.propTypes = {
  amount: PropTypes.number,
};

FirstInstallmentBenefit.defaultProps = {
  amount: undefined,
};

export default FirstInstallmentBenefit;
