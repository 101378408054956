import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useLocalStorage } from '@uidotdev/usehooks';
import {
  authenticate, login, askPasswordReset,
} from '../../api/authenticationApiService';
import { publicRoutes } from '../../constants/routesConstants';
import { COMMERCE_CREDENTIALS_USERNAME } from '../../constants/localStorageVariablesConstants';
import { LOGIN_PASSWORD_EXPIRED } from '../../constants/apiErrorCodeConstants';
import { useAuth } from '../../contexts/AuthContext';
import { getAuthenticationError } from '../../services/errorApiService';
import useNavigation from '../useNavigation';
import { useToaster } from '../../contexts/ToasterContext';
import i18n from '../../common/i18n';
import { getVisibleNotifications } from '../../api/notificationApiService';

const useLoginController = () => {
  const [rememberedUsername, saveRememberedUsername] = useLocalStorage(COMMERCE_CREDENTIALS_USERNAME);
  const { navigation, goToLoginPage } = useNavigation();
  const { saveLoggedUser } = useAuth();
  const queryParams = new URLSearchParams(useLocation().search);
  const { showToast } = useToaster();

  const [loading, setLoading] = useState();
  const [authenticationError, setAuthenticationError] = useState();
  const [wasRestored, setWasRestored] = useState();
  const [customerEmail, setCustomerEmail] = useState();

  const goToForgotPassword = () => {
    navigation(publicRoutes.forgotPassword);
  };

  const doLogin = async (data) => {
    const credentials = { username: data?.user, password: data?.pass };
    try {
      setLoading(true);
      await login(credentials);
      const res = await authenticate();
      const loggedUser = res.data;
      const { data: notificationAux } = await getVisibleNotifications();
      console.log(notificationAux);
      loggedUser.notification = notificationAux;
      saveLoggedUser(loggedUser);
      saveRememberedUsername(data.remember ? credentials.username : undefined);
    } catch (error) {
      const authError = getAuthenticationError(error);
      if (authError.code === LOGIN_PASSWORD_EXPIRED) {
        navigation(publicRoutes.expiredPassword, credentials);
      } else {
        setAuthenticationError(authError);
      }
    } finally {
      setLoading(false);
    }
  };

  const doRecover = async ({ user }) => {
    try {
      setLoading(true);
      const { data: { email } } = await askPasswordReset(user);
      setCustomerEmail(email);
      setWasRestored(true);
    } catch (error) {
      setAuthenticationError(getAuthenticationError(error));
    } finally {
      setLoading(false);
    }
  };

  const goToLogin = () => {
    goToLoginPage();
  };

  const init = () => {
    window.scrollTo(0, 0);
    if (queryParams.has('expired')) {
      showToast(i18n.Login.sessionExpired, 'error');
      queryParams.delete('expired');
    }
  };

  useEffect(init, []);

  return {
    loading, rememberedUsername, doLogin, authenticationError, goToForgotPassword, doRecover, goToLogin, wasRestored, customerEmail,
  };
};

export default useLoginController;
