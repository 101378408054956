export const getAvailableAmount = (lr) => {
  let availableAmount;
  if (lr.response.prePaidLoan) {
    availableAmount = lr.response.maxAmount - lr.response.prePaidLoanAmount;
  } else {
    availableAmount = lr.response.maxAmount;
  }
  if (lr.advancePaymentPercentage) {
    availableAmount += (availableAmount * lr.advancePaymentPercentage) / 100;
  }
  return availableAmount;
};

export const getMaxAmountAvailableConsideringDualOffer = (lr) => {
  const amount = lr.parallelOfferSelected ? lr.maxAmountDual : getAvailableAmount(lr);
  return amount;
};

export const getProductName = (lr, productList) => {
  const product = productList.find((productAux) => productAux.idProducto === lr.productId);
  return product?.nombreProducto?.toLowerCase();
};
export const getCalculatedAmountToDisburse = (lr) => {
  let disbursementAmount = lr.response.amountToDisburse;
  if (lr.advancePaymentAmount) {
    disbursementAmount += lr.advancePaymentAmount;
  }
  return disbursementAmount;
};

export const isPrePaid = (lr) => !!lr.response.prePaidLoan;

export const getPrePaidAmount = (lr) => {
  let prePaidAmount = 0;
  if (lr.showCancelablesFlex && lr.loanToBePrePaid && lr.loanToBePrePaid.length > 0) {
    prePaidAmount = Math.ceil(lr.loanToBePrePaid.reduce((amount, loan) => amount + loan.amount, 0));
  } else {
    prePaidAmount = lr.response.prePaidLoanAmount;
  }
  return prePaidAmount;
};

export const isProductToPayVehicle = (lr) => lr.productToPay?.vehicleType;

export const getVehicleBrand = (lr) => lr.productToPay?.product.productAttributes.brand;

export const getVehicleModel = (lr) => lr.productToPay.product.productAttributes.model;

export const hasAdvancePayment = (lr) => lr.advancePaymentPercentage > 0;
